import {
  LazyQueryHookOptions,
  OperationVariables,
  QueryTuple
} from '@apollo/client';

import { FilesData } from './Files.types';
import { TreatmentInfo } from './InTreatmetn.types';
import { ToothOptionInput } from './PatientList.types';
import { Option } from './select';
import { TeethExtraction } from './TreatmentPlanning.types';

export interface TreatmentPlanVersions {
  id: string;
  treatmentPlanId: string;
  acceptanceStatus: string;
  prescription: {
    type: TPVFormTypes;
    treatmentGoals: string[];
    treatmentGoalsProcedures: TreatmentGoalsProcedures;
    archesToTreat: string[];
    toothMovementRestrictions: string;
    overbite: string;
    midlines: string;
    midlinesShifts: string[];
    teethToExtract: string;
    prosthesis: string;
    prosthesisIprToAvoid: string;
    prosthesisAttachmentToAvoid: string;
    specialNotes: string;
  };
}

export enum TPVFormTypes {
  ZENPLUS = 'ZENCLEAR_PLUS',
  ZENCLEAR = 'ZENCLEAR'
}
export enum TemplateFormTypes {
  ZenClear = 'ZenClear',
  ZenPlus = 'ZenPlus',
  Refinement = 'Refinement',
  ZenClearRefinement = 'ZenClearRefinement',
  ZenPlusRefinement = 'ZenPlusRefinement'
}

export enum TREATMENT_STAGES {
  PRE_ASSESSMENT,
  PENDING_PRESCRIPTION,
  TREATMENT_PLAN_REVIEW,
  IN_TREATMENT,
  END_OF_TREATMENT_REVIEW,
  PRESCRIPTIONS,
  PRESCRIPTION,
  PURCHASE,
  PRODUCTION_AND_DELIVERY,
  TREATMENT
}

export enum ALIGNER_INFO_TYPE {
  SET = 'Set',
  ZEN_STAGE = 'Zenstage'
}

export type ZenclearFormType = {
  treatmentGoals?: string[];
  archesToTreat: string;
  toothMovementRestrictions: string;
  prosthesis?: string;
  prosthesisIprToAvoid?: TeethExtraction;
  prosthesisAttachmentToAvoid?: TeethExtraction;
  teethToExtract?: TeethExtraction;
  specialNotes: string;
  translatedSpecialNotes?: string;
  treatmentPlanningLab?: Option;
  manufacturer?: Option;
  extractTeeth: string;
  teethNotToMove?: TeethExtraction;
  ipr?: boolean;
  attachment?: boolean;
};
export type ReUploadFormType = {
  treatmentFiles: {
    photo: FilesData[];
    scan: FilesData[];
    xray: FilesData[];
  };
};
export type RefinementFormType = {
  addButtonsForElasticsCheck?: boolean;
  attachmentsOnlyCheck?: boolean;
  reasonForSubmission: Option;
  alignerInputUpperSet?: number;
  treatmentPlanningLab?: Option;
  alignerInputLowerSet?: number;
  alignerInputUpperZenstage?: Option;
  alignerInputLowerZenstage?: Option;
  radioUpper?: string;
  radioLower?: string;
  archesToTreat: Option;
  refinementGoals?: Option[];
  toothChart3?: string[];
  toothChart2: Record<string, string>;
  attachments?: Option;
  attachmentsOnly?: TeethExtraction;
  buttonsForElastics?: TeethExtraction;
  iprPerformed: Option;
  specialNotes: string;
  translatedSpecialNotes?: string;
  attachmentsSelect: Option;
  designId?: string;
  iprInfoText: string;
  totalAlignerSet?: string;
  formType: string;
  iprInfo?: IprInfo[];
  manufacturer?: Option;
  AlignerInput?: any;
};
export type TreatmentGoalsProcedures = {
  [x: string]: any;
};

export type ZenPlusFormType = {
  treatmentGoals?: string[];
  treatmentGoalsProcedures?: TreatmentGoalsProcedures;
  proceduresInnerFields?: any;
  upperLowerProcedures?: any;
  archesToTreat: string;
  teethNotToMove?: TeethExtraction;
  toothMovementRestrictions: string;
  overbite?: string;
  midlines?: string;
  shiftUpper: boolean;
  shiftLower: boolean;
  shiftUpperPosition: string;
  shiftLowerPosition: string;
  prosthesis?: string;
  prosthesisIprToAvoid?: TeethExtraction;
  prosthesisAttachmentToAvoid?: TeethExtraction;
  teethToExtract?: TeethExtraction;
  extractTeeth: string;
  specialNotes: string;
  translatedSpecialNotes?: string;
  treatmentPlanningLab?: Option;
  manufacturer?: Option;
  ipr?: boolean;
  attachment?: boolean;
};
export interface ClinicalPreferencesApiInput {
  iprSize?: string[];
  iprLocation?: string[];
  biteRamps?: string;
  premolarCrossbite?: string;
  extrusionElastics?: string;
  others?: string;
}
export type ZenclearApiInput = {
  type?: TPVFormTypes.ZENCLEAR;
  treatmentGoals?: any;
  archesToTreat?: string[];
  toothMovementRestrictions?: string | null;
  prosthesis?: string;
  prosthesisIprToAvoid?: string;
  prosthesisAttachmentToAvoid?: string;
  teethToExtract?: string;
  specialNotes?: string;
  translatedSpecialNotes?: string;
  clinicalPreferences?: ClinicalPreferencesApiInput;
  prosthesisOptions?: string[];
};

export type RefinementTPVApiInput = {
  type: TPVFormTypes;
  specialNotes?: string;
  translatedSpecialNotes?: string;
  submissionReason: string;
  refinementGoals: any;
  attachments: string;
  alignerInfo: any;
  iprInfo: any;
  archesToTreat: string[];
  designId?: string;
  iprInfoText?: string;
  totalAlignerSet?: string;
  attachmentsOnlyCheck?: boolean;
  addButtonsForElasticsCheck?: boolean;
};

export type ZenPlusApiInput = {
  type?: TPVFormTypes.ZENPLUS;
  treatmentGoals?: any;
  archesToTreat?: string[];
  toothMovementRestrictions?: string | null;
  prosthesis?: string;
  prosthesisIprToAvoid?: string;
  prosthesisAttachmentToAvoid?: string;
  overbite?: string;
  midlines?: string;
  midlinesShifts?: string[];
  teethToExtract?: string;
  specialNotes?: string;
  translatedSpecialNotes?: string;
  clinicalPreferences?: ClinicalPreferencesApiInput;
  prosthesisOptions?: string[];
};
export type TpvApiInput = {
  attachmentsOnlyCheck?: boolean;
  addButtonsForElasticsCheck?: boolean;
  treatmentGoals?: any;
  archesToTreat?: string[];
  toothMovementRestrictions?: string | null;
  prosthesis?: string;
  prosthesisIprToAvoid?: string;
  prosthesisAttachmentToAvoid?: string;
  teethToExtract?: string;
  specialNotes?: string;
  translatedSpecialNotes?: string;
  prosthesisOptions?: string[];
  overbite?: string;
  midlines?: string;
  midlinesShifts?: string[];
  clinicalPreferences?: ClinicalPreferencesApiInput;
  type?: TPVFormTypes;
  submissionReason?: string;
  refinementGoals?: ToothOptionInput[];
  attachments?: string;
  alignerInfo?: any;
  iprInfo?: any;
  designId?: string;
  iprInfoText?: string;
  totalAlignerSet?: string;
};
export type CreateTpvPopupFormType = {
  treatmentType: {
    label: string;
    value?: string;
  };
  zenClearVersion: {
    label?: string;
    value?: string;
  };
  isResim: boolean;
  resimInstructions: string;
};

export type IprInfo = {
  setNumber: string;
  position: string;
  value: string;
};

export type AlignerIPRInfo = {
  alignerInfo?: TreatmentInfo;
  iprInfo?: IprInfo[];
  smileSummaryUrl?: string;
  missingTooth: string[];
  treatmentPlanningLab?: string;
  id?: string;
  manufacturer?: string;
  treatmentPlanVersionType?: string;
};

export type TPVFormField = {
  name: string;
  value: any;
};

export type GetTreatmentPlanVersions = {
  getTreatmentPlanVersions: {
    id: string;
    urlSmileSummary: string;
    treatmentPlan: {
      type: string;
    };
    details: {
      designSpecification: {
        ipr: IprInfo[];
      };
    };
    type: string;
    treatmentPlanningLab: string;
    manufacturer: string;
  }[];
};

export interface GetSupportedDesignLab {
  designLabs: string[];
  manufacturers: string[];
}

export interface GetSupportedDesignLabsList {
  getSupportedDesignLabs: GetSupportedDesignLab;
}

export type GetSupportedDesignLabs = {
  (config?: LazyQueryHookOptions<GetSupportedDesignLabsList>): QueryTuple<
    GetSupportedDesignLabsList,
    OperationVariables
  >;
};
export interface TranslateTextOutput {
  result: string;
}
export const ZENPLUS = 'ZenyumClear™ Plus';
export const ZENCLEAR = 'ZenyumClear™';

export interface TreatmentPlanVersionButtonVisibility {
  disableApproveButton: boolean;
  hideButtons: boolean;
  disableSubmitEnquiryButton: boolean;
  hideSubmiEnquiryButton: boolean;
  hideApproveButton: boolean;
  disableCreateZenchatButton: boolean;
  hideCreateZenchatButton: boolean;
  showReopenTpv: boolean;
  hideRejectButton: boolean;
}
