import { ChangeEvent, FC, ReactNode, useEffect, useRef, useState } from 'react';

import {
  CameraIcon,
  CloseIcon,
  ErrorIcon,
  HorizontalFlipIcon,
  PdfIcon,
  VerticalFlipIcon
} from 'assets/icons';
import { ProgressiveImage } from 'components';
import { If, ProgressBar } from 'components/Generics';
import { TreatmentSourceType } from 'components/TreatmentProgressMenu/TreatmentMenuConfig';
import {
  ACCEPTED_IMAGE_FORMATS,
  ALLOWED_IMAGE_EXTENSIONS,
  ALLOWED_PHOTO_MIME_TYPE
} from 'constants/common';
import useFileUpload from 'containers/TreatmentFileUploader/fileUpload.hooks';
import { Classification, FilesData } from 'types/Files.types';
import {
  TreatmentDocument,
  TreatmentDocumentInput,
  TreatmentDocumentType
} from 'types/TreatmentPlanning.types';
import { translate } from 'utils';
import { getFileExtension } from 'utils/common';
import useReferralFileUpload from 'utils/customHooks/fileUpload.hooks';
import { debouncedFlip } from 'utils/patientpageUtils';
import { showToast } from 'utils/toast/toast.util';

interface Props {
  label: string;
  icon: ReactNode;
  fileData?: FilesData;
  classification: Classification;
  selectedDocumentType?: TreatmentDocumentType;
  isError?: boolean;
  handleUploadFile: (
    fileUploadInput: TreatmentDocumentInput,
    file: FilesData
  ) => void;
  setShowScanPopup?: (show: boolean) => void;
  updateTreatmentFile: (data: TreatmentDocument[]) => void;
  toggleZoomPhoto?: React.Dispatch<React.SetStateAction<boolean>>;
  setFiles: React.Dispatch<React.SetStateAction<FilesData[]>>;
  setSelectedPhoto?: (id: string | number) => void;
  files: FilesData[];
  treatmentId: string;
  referralId?: string;
  documentType?: TreatmentDocumentType | undefined;
  sourceType?: TreatmentSourceType | undefined;
}
const PhotosFileCard: FC<Props> = ({
  label,
  icon,
  handleUploadFile,
  classification,
  fileData,
  selectedDocumentType,
  isError = false,
  setShowScanPopup,
  toggleZoomPhoto,
  setSelectedPhoto,
  setFiles,
  files,
  updateTreatmentFile,
  treatmentId,
  referralId,
  documentType = TreatmentDocumentType.PHOTO,
  sourceType = TreatmentSourceType.PRESCRIPTIONS
}) => {
  const { uploadProgress } = fileData || {};
  const [fileContent, setFileContent] = useState<FilesData>();
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const { uploadFile } = useFileUpload({
    treatmentId,
    documentType,
    sourceType,
    setFiles,
    isImageFlip: true
  });

  const { uploadFile: uploadReferralFile } = useReferralFileUpload({
    referralId,
    treatmentId,
    setFiles,
    isImageFlip: true
  });

  useEffect(() => {
    setFileContent(fileData);
  }, [fileData]);

  const handleClick = () => {
    hiddenFileInput.current?.click();
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const uploadedFile = (event.target as HTMLInputElement).files?.[0];

    if (uploadedFile) {
      const fileExtension = getFileExtension(uploadedFile.name);
      if (!ALLOWED_IMAGE_EXTENSIONS.includes(fileExtension)) {
        showToast(
          translate('error.validFileType', {
            X: uploadedFile.name
          }),
          false
        );
        return;
      }
      const objectUrl = URL.createObjectURL(uploadedFile);
      const fileUploadInput: TreatmentDocumentInput = {
        name: uploadedFile?.name,
        sourceType: 'PRESCRIPTIONS',
        documentType: selectedDocumentType,
        classification: classification
      };
      const file: FilesData = {
        classification,
        id: new Date().toISOString(),
        fileType: ACCEPTED_IMAGE_FORMATS.includes(uploadedFile?.type)
          ? 'img'
          : 'file',
        fileSrc: objectUrl,
        file: uploadedFile
      };
      setFileContent(file);
      handleUploadFile(fileUploadInput, file);
    }
  };

  const handleDelete = (fileId: string) => {
    setFiles((prevFile) =>
      prevFile.map((file) => {
        if (file.id === fileId) {
          file.classification = Classification.OTHER;
        }
        return file;
      })
    );
    updateTreatmentFile([{ documentId: fileId, classification: null }]);
  };

  const handleImageFlip = async (fileId: string, direction: string) => {
    if (!fileContent?.fileId) return;

    const image = new Image();
    image.crossOrigin = 'anonymous';
    await fetch(fileContent.fileSrc, { cache: 'reload' });
    image.src = fileContent.fileSrc;
    await new Promise((resolve) => (image.onload = resolve));

    // Create a canvas to manipulate the image
    const canvas = document.createElement('canvas');
    canvas.width = image.width;
    canvas.height = image.height;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    // Flip the image both horizontally and vertically
    if (direction === 'horizontal') {
      ctx.translate(canvas.width, 0);
      ctx.scale(-1, 1);
      ctx.drawImage(image, 0, 0);
    } else if (direction === 'vertical') {
      ctx.translate(0, canvas.height);
      ctx.scale(1, -1);
      ctx.drawImage(image, 0, 0);
    }

    // Get the flipped image as a Blob
    canvas.toBlob((blob) => {
      if (!blob) return;

      // Create a new file from the Blob
      const flippedFile = new File([blob], fileContent?.fileName || '', {
        type: fileContent.key
      });

      // Replace the image in UI and initiate the API call
      const flippedImageUrl = URL.createObjectURL(flippedFile);

      if (!referralId) {
        uploadFile([fileContent], flippedFile);
      } else {
        const date = new Date();
        const fileUploadInput: TreatmentDocumentInput = {
          name: `${date.getTime()} ${fileContent.file?.name}` as string,
          sourceType: 'PRESCRIPTIONS',
          documentType: selectedDocumentType,
          classification: classification,
          key: fileContent.S3key
        };
        const flippedFileData: FilesData = {
          classification: fileContent.classification,
          id: new Date().toISOString(),
          fileType: 'img',
          fileSrc: flippedImageUrl,
          file: flippedFile
        };
        uploadReferralFile(fileUploadInput, flippedFileData);
      }

      setFiles((prevFile) =>
        prevFile.map((file) => {
          if (file.id === fileId) {
            file.fileSrc = flippedImageUrl;
          }
          return file;
        })
      );
    }, fileContent.key);
  };

  const imageFlip = (fileId: string, direction: string) => {
    debouncedFlip(fileId, direction, handleImageFlip);
  };

  const handleDropEvent = (event: any) => {
    const fileId = event.dataTransfer.getData('id');

    const updateData: TreatmentDocument[] = [];

    const droppedFile = files.find((file: FilesData) => {
      const isDroppedFile = file.id === fileId;
      if (isDroppedFile && file.classification) {
        return file;
      }
    });

    if (fileId) {
      updateData.push({
        documentId: fileId,
        classification: classification,
        documentType: selectedDocumentType
      });
    }
    if (fileContent?.id) {
      updateData.push({
        documentId: fileContent.id,
        classification: droppedFile?.classification || null,
        documentType: selectedDocumentType
      });
    }
    setFiles((prevFile) => {
      return prevFile.map((file) => {
        const isDroppedFile = file.id === fileId;
        const isRemovedFile = file.id === fileContent?.id;
        if (isDroppedFile) {
          file.classification = classification;
        } else if (isRemovedFile) {
          if (droppedFile) file.classification = droppedFile.classification;
          else file.classification = Classification.OTHER;
        }
        return file;
      });
    });
    updateTreatmentFile(updateData);
  };

  const handlePhotoDrag = (event: React.DragEvent<HTMLDivElement>) => {
    if (fileContent?.id) {
      event.dataTransfer.setData('id', `${fileContent.id}`);
    }
  };

  const handlePreviewClick = () => {
    if (fileContent?.fileType === 'img') {
      toggleZoomPhoto?.(true);
      setSelectedPhoto?.(fileContent.id || fileContent.fileId || '');
    } else {
      window.open(fileContent?.fileSrc, '_blank');
    }
  };

  return (
    <div
      id={classification}
      draggable='true'
      onDragStart={handlePhotoDrag}
      onDrop={handleDropEvent}
      className='bg-GRAY5 flex flex-col rounded-lg  box-border  w-full h-full relative overflow-hidden'
    >
      <div className='items-center flex-1 h-full w-full'>
        {fileContent?.fileSrc ? (
          <>
            <button onClick={handlePreviewClick} className='h-full w-full'>
              {fileContent?.fileType === 'img' && fileContent.fileSrc ? (
                <ProgressiveImage
                  src={fileContent.fileSrc}
                  placeholder={fileContent.thumbnail}
                  alt='img'
                  className='h-full w-full object-cover'
                />
              ) : (
                <div className='flex w-full h-full justify-center items-center'>
                  <PdfIcon
                    title='pdf-icon'
                    className='w-16 h-16 md:mt-0.1rem mt-0.75'
                  />
                </div>
              )}
            </button>

            <If
              condition={
                uploadProgress != undefined && (uploadProgress as number) < 100
              }
            >
              <ProgressBar
                percentage={uploadProgress}
                className='absolute mx-1 md:mx-2 bottom-2'
              />
            </If>
          </>
        ) : (
          <div className='px-4 h-full flex justify-center items-center'>
            {icon}
          </div>
        )}
      </div>
      {!fileContent?.fileSrc ? (
        <>
          <div className='absolute w-full p-3 flex justify-between hidden'>
            <button
              className='  bg-PRIMARY rounded-full text-white w-6 h-6 items-center flex justify-center'
              onClick={() => setShowScanPopup?.(true)}
            >
              <CameraIcon className='w-3 h-3' />
            </button>
          </div>
          <div className='w-full absolute bottom-3 flex justify-center items-center'>
            <input
              type='file'
              onChange={handleChange}
              ref={hiddenFileInput}
              className='hidden'
              accept={ALLOWED_PHOTO_MIME_TYPE}
            />
            <button
              id='Buttons'
              onClick={handleClick}
              className='bg-DEFAULT_TEXT rounded-3xl text-white px-3 py-1 text-xs font-normal hover:opacity-90 hover:scale-105'
            >
              {translate('sidepanel_appointmentlist.add')} {translate(label)}
            </button>
          </div>
          <If condition={isError}>
            <div className='absolute right-3 top-3 w-5 h-5 text-ERROR'>
              <ErrorIcon className='w-5 h-5' />
            </div>
          </If>
        </>
      ) : (
        <>
          <button
            className='absolute right-3 top-3 bg-PRIMARY rounded-full text-white w-6 h-6 items-center flex justify-center'
            onClick={() =>
              fileContent?.fileId && handleDelete(fileContent?.fileId)
            }
          >
            <CloseIcon className='w-3 h-3' />
          </button>
          <button
            className='absolute right-20 bg-BLACK top-3 rounded-full text-white w-6 h-6 items-center flex justify-center'
            onClick={() =>
              fileContent?.fileId && imageFlip(fileContent?.fileId, 'vertical')
            }
          >
            <VerticalFlipIcon className='w-6 h-6' />
          </button>
          <button
            className='absolute right-3 bg-BLACK top-20 rounded-full text-white w-6 h-6 items-center flex justify-center'
            onClick={() =>
              fileContent?.fileId &&
              imageFlip(fileContent?.fileId, 'horizontal')
            }
          >
            <HorizontalFlipIcon className='w-6 h-6' />
          </button>
          <div className='w-full absolute bottom-3 flex justify-center items-center'>
            <label className=' bg-DEFAULT_TEXT rounded-3xl text-white px-3 py-1 text-xs font-normal'>
              {translate(label)}
            </label>
          </div>
        </>
      )}
    </div>
  );
};

export default PhotosFileCard;
