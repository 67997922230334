import { DocumentNode, useApolloClient } from '@apollo/client';

import { FINANCE_SUMMARY_STATUS } from 'constants/Finance.constants';
import { REQUEST_METHODS } from 'constants/index';
import { RESUBMISSION_PENDING } from 'constants/patientCard';
import { callApi } from 'services/api/request';
import {
  useUpdateReferralDocument,
  deleteReferralDocument
} from 'services/hooks/Referrals.hooks';
import {
  deleteTreatmentDocument,
  useUpdateTreatmentDocument
} from 'services/hooks/TreatmentPlanning.hooks';
import {
  FINANCE_FILE_UPLOAD,
  UPDATE_QUOTE
} from 'services/query/financeSummary.queries';
import {
  REFERRAL_FILE_UPLOAD,
  SAVE_REFERRAL_DOCUMENTS
} from 'services/query/referralFileUpload';
import {
  ATTACH_TREATMENT_DOCUMENTS,
  GET_TREATMENT_FILE_UPLOAD_URL
} from 'services/query/treatmentPlanning';
import {
  FilesData,
  FinanceDocumentUploadUrlResponse,
  FinanceFileUploadInput,
  ReferralDocumentListInput,
  ReferralDocumentResponse,
  ReferralDocumentUploadUrlResponse
} from 'types/Files.types';
import { updateQuote } from 'types/FinanceReport.types';
import {
  TreatmentDocument,
  TreatmentDocumentInput,
  TreatmentDocumentRes,
  TreatmentDocumentsUpdateInput,
  TreatmentDocumentUploadInput,
  TreatmentDocumentUploadUrlRes
} from 'types/TreatmentPlanning.types';

interface Props {
  treatmentId?: string;
  referralId?: string;
  quoteId?: string;
  isResubmission?: boolean;
  setFiles?: React.Dispatch<React.SetStateAction<FilesData[]>>;
  refetchQueries?: Array<DocumentNode>;
  isImageFlip?: boolean;
}
const useFileUpload = ({
  treatmentId,
  referralId,
  quoteId,
  setFiles,
  isResubmission,
  refetchQueries,
  isImageFlip = false
}: Props) => {
  const [deleteDocument] = deleteTreatmentDocument();
  const [deleteReferralDocuments] = deleteReferralDocument();
  const [updateDocument] = useUpdateTreatmentDocument();
  const [updateReferralDocument] = useUpdateReferralDocument();
  const client = useApolloClient();
  const isReferral = !!referralId;
  const isFinanceTab = !!quoteId;
  const getFileUploadUrl = async (fileUploadInput: TreatmentDocumentInput) => {
    const date = new Date();
    fileUploadInput.name = `${date.getTime()} ${fileUploadInput.name}`;
    if (isReferral) {
      const variables = {
        documents: [
          {
            name: fileUploadInput.name,
            type: fileUploadInput.documentType,
            ...(isImageFlip && { key: fileUploadInput.key })
          }
        ],
        referralId: referralId || ''
      };
      return client.query<
        ReferralDocumentUploadUrlResponse,
        ReferralDocumentListInput
      >({
        query: REFERRAL_FILE_UPLOAD,
        variables,
        fetchPolicy: 'network-only'
      });
    } else if (isFinanceTab) {
      return client.mutate<
        FinanceDocumentUploadUrlResponse,
        FinanceFileUploadInput
      >({
        mutation: FINANCE_FILE_UPLOAD,
        variables: {
          id: quoteId,
          name: fileUploadInput.name
        },
        fetchPolicy: 'network-only'
      });
    } else {
      return client.query<
        TreatmentDocumentUploadUrlRes,
        TreatmentDocumentUploadInput
      >({
        query: GET_TREATMENT_FILE_UPLOAD_URL,
        variables: {
          documents: [fileUploadInput],
          treatmentId: treatmentId || ''
        },
        fetchPolicy: 'network-only'
      });
    }
  };

  const addKeyToUploadedFile = (key: string, file: FilesData) => {
    setFiles?.((files) => {
      const updatedFile = files.find(({ id }) => id === file.id);
      if (updatedFile) {
        updatedFile.key = key;
        return [...files];
      }
      return files;
    });
  };

  const uploadFileToS3 = async (url: string, file: FilesData) => {
    const onUploadProgress = (progressEvent: ProgressEvent) => {
      const progress = (progressEvent.loaded * 100) / progressEvent.total;
      setFiles?.((files) => {
        const updatedFile = files.find(({ id }) => id === file.id);
        if (updatedFile) {
          updatedFile.uploadProgress = progress;
          return [...files];
        }
        file.uploadProgress = progress;
        return files.concat(file);
      });
    };
    const imgBody = new Blob([file.file as File], {
      type: file.file?.type
    });
    return callApi({
      payload: { method: REQUEST_METHODS.PUT, url, data: imgBody },
      headers: { 'Content-Type': `${file.file?.type}` },
      onUploadProgress
    });
  };

  const updateIdOfUploadedFile = (
    uploadedFile:
      | TreatmentDocumentRes
      | ReferralDocumentResponse
      | FinanceDocumentUploadUrlResponse
      | null
      | undefined,
    file: FilesData
  ) => {
    setFiles?.((files) => {
      const updatedFile = files.find(({ id }) => id === file.id);
      if (updatedFile) {
        if (isReferral) {
          updatedFile.fileId = (
            uploadedFile as ReferralDocumentResponse
          )?.saveReferralDocuments[0].id;
          updatedFile.fileSrc =
            (uploadedFile as ReferralDocumentResponse)?.saveReferralDocuments[0]
              .url || '';
          return [...files];
        } else {
          updatedFile.fileId = (
            uploadedFile as TreatmentDocumentRes
          )?.attachTreatmentDocuments[0].id;
          updatedFile.fileSrc =
            (uploadedFile as TreatmentDocumentRes)?.attachTreatmentDocuments[0]
              .url || '';
          return [...files];
        }
      }
      return files;
    });
  };

  const saveUploadedFile = async (fileUploadInput: TreatmentDocumentInput) => {
    if (isReferral) {
      const referralFileUploadInput = {
        name: fileUploadInput.name,
        type: fileUploadInput.documentType,
        key: fileUploadInput.key,
        classification: fileUploadInput.classification
      };
      return client.mutate<ReferralDocumentResponse, ReferralDocumentListInput>(
        {
          mutation: SAVE_REFERRAL_DOCUMENTS,
          variables: {
            documents: [referralFileUploadInput],
            referralId: referralId || ''
          },
          refetchQueries,
          fetchPolicy: 'network-only'
        }
      );
    }
    if (isResubmission) {
      fileUploadInput.resubmissionStatus = RESUBMISSION_PENDING;
    }
    if (isFinanceTab) {
      return client.mutate<FinanceDocumentUploadUrlResponse, updateQuote>({
        mutation: UPDATE_QUOTE,
        variables: {
          id: quoteId,
          input: {
            status: FINANCE_SUMMARY_STATUS.INVOICE_COMPLETED,
            invoiceKey: fileUploadInput.key || ''
          }
        },
        refetchQueries,
        fetchPolicy: 'network-only'
      });
    }
    return client.mutate<TreatmentDocumentRes, TreatmentDocumentUploadInput>({
      mutation: ATTACH_TREATMENT_DOCUMENTS,
      variables: {
        documents: [fileUploadInput],
        treatmentId: treatmentId || ''
      },
      refetchQueries,
      fetchPolicy: 'network-only'
    });
  };

  const uploadFile = async (
    fileUploadInput: TreatmentDocumentInput,
    file: FilesData
  ) => {
    try {
      let url = '';
      let key = '';
      const { data: documentUrl } = await getFileUploadUrl(fileUploadInput);
      if (isReferral) {
        const { url: inputUrl, key: inputKey } =
          (documentUrl as ReferralDocumentUploadUrlResponse)
            ?.getReferralDocumentsUploadUrl[0] || {};
        url = inputUrl;
        key = inputKey;
      } else if (isFinanceTab) {
        const { presignedUrl: inputUrl, key: inputKey } =
          (documentUrl as FinanceDocumentUploadUrlResponse)
            ?.getInvoiceUploadUrl || {};
        url = inputUrl;
        key = inputKey;
      } else {
        const { url: inputUrl, key: inputKey } =
          (documentUrl as TreatmentDocumentUploadUrlRes)
            ?.getTreatmentDocumentsUploadUrl[0] || {};
        url = inputUrl;
        key = inputKey;
      }
      await uploadFileToS3(url, file);
      fileUploadInput.key = key;
      addKeyToUploadedFile(key, file);
      if (!isImageFlip) {
        const { data } = await saveUploadedFile(fileUploadInput);
        updateIdOfUploadedFile(data, file);
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const onDelete = (
    id: string | number | undefined,
    localId?: string | number,
    successHandler?: (id: string | number) => void
  ) => {
    if (id) {
      const documentId = String(id);
      if (isReferral) {
        deleteReferralDocuments({
          variables: {
            referralId: referralId || '',
            id: documentId
          }
        })
          .then(() => {
            setFiles?.((files) => {
              return files.filter(({ fileId }) => fileId !== id);
            });
            successHandler?.(documentId);
          })
          .catch(console.log);
      } else {
        deleteDocument({
          variables: {
            treatmentId: treatmentId || '',
            documentId
          }
        })
          .then(() => {
            setFiles?.((files) => {
              return files.filter(({ fileId }) => fileId !== id);
            });
            successHandler?.(documentId);
          })
          .catch(console.log);
      }
    } else {
      setFiles?.((files) => {
        return files.filter(({ id }) => id !== localId);
      });
    }
  };

  const updateTreatmentFile = (updateDocuments: TreatmentDocument[]) => {
    const variables: TreatmentDocumentsUpdateInput = {
      input: updateDocuments
    };
    if (isReferral) {
      variables.referralId = referralId;
      updateReferralDocument({ variables, refetchQueries });
    } else {
      variables.treatmentId = treatmentId;
      updateDocument({
        variables,
        refetchQueries
      });
    }
  };

  const refetchQuery = async () => {
    await client.refetchQueries({
      include: refetchQueries
    });
  };

  return {
    getFileUploadUrl,
    uploadFileToS3,
    uploadFile,
    onDelete,
    updateTreatmentFile,
    refetchQuery
  };
};

export default useFileUpload;
