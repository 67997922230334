import { FC } from 'react';

import classNames from 'classnames';
import _ from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';

import { FormLabel } from 'components/FormLabel/FormLabel';
import RhfSelect from 'components/Generics/Rhf/RhfSelect';
import InfoToolTip from 'components/InfoToolTip';
import { RHFTextArea } from 'components/RHFTextArea/RHFTextArea';
import {
  BITE_RAMP,
  PREMOLAR_CROSSBITE
} from 'constants/treatmentPlanningPreferenceTemplate';
import { translate } from 'utils';

import IprSizeAndLocationDropdowns from './IprSizeAndLocationDropdowns';

type Props = {
  isPrescription?: boolean;
  isVieworEdit?: boolean;
  fieldNames: {
    iprSizeName: string;
    iprLocationName: string;
    biteRampsName: string;
    premolarCrossbiteName: string;
    extrusionElastics: string;
    others: string;
  };
  isDisabled?: boolean;
  isReferral?: boolean;
};
const ZenPLusPreferenceTemplate: FC<Props> = ({
  isPrescription = false,
  fieldNames,
  isVieworEdit = false,
  isDisabled = false,
  isReferral = false
}) => {
  const {
    control,
    formState: { errors }
  } = useFormContext();
  return (
    <div className={classNames({ 'bg-GRAY5': !isReferral && !isVieworEdit })}>
      <IprSizeAndLocationDropdowns
        isDisabled={isDisabled}
        isVieworEdit={isVieworEdit}
        isPrescription={isPrescription}
        iprLocationName={fieldNames.iprLocationName}
        iprSizeName={fieldNames.iprSizeName}
        isReferral={isReferral}
      />

      <div
        className={classNames('space-y-6', {
          ' px-5 pt-2 pb-10 rounded-lg': isPrescription && !isVieworEdit,
          'mt-7': !isPrescription || isVieworEdit
        })}
      >
        <Controller
          control={control}
          name={fieldNames.biteRampsName}
          render={({ field }) => (
            <>
              <div>
                <div className='flex space-x-4'>
                  <FormLabel
                    label={
                      isPrescription
                        ? `3. ${translate('prescription.bite.ramps')}`
                        : `4. ${translate('prescription.bite.ramps')}`
                    }
                    error={!!_.get(errors, fieldNames.biteRampsName)}
                    name={fieldNames.biteRampsName}
                    className='text-sm mb-2.5'
                  />
                  <InfoToolTip
                    message={translate('biteRamp.message')}
                    className='w-5 h-5 text-PRIMARY'
                    toolTipStyle='w-45 md:w-full'
                  />
                </div>

                <div className='w-full md:w-325px space-y-7 mr-[2rem]'>
                  <RhfSelect
                    isDisabled={isDisabled}
                    name={fieldNames.biteRampsName}
                    options={BITE_RAMP}
                    onChange={(data) => {
                      field.onChange(data);
                    }}
                    inputProps={{
                      controlShouldRenderValue: true
                    }}
                  />
                </div>
              </div>
            </>
          )}
        />
        <Controller
          control={control}
          name={fieldNames.premolarCrossbiteName}
          render={({ field }) => (
            <>
              <div>
                <FormLabel
                  label={
                    isPrescription
                      ? `4. ${translate('prescription.premolar.crossbite')}`
                      : `5. ${translate('prescription.premolar.crossbite')}`
                  }
                  error={!!_.get(errors, fieldNames.premolarCrossbiteName)}
                  name={fieldNames.premolarCrossbiteName}
                  className='text-sm mb-2.5'
                />
                <div className='w-full md:w-325px space-y-7'>
                  <RhfSelect
                    isDisabled={isDisabled}
                    name={fieldNames.premolarCrossbiteName}
                    options={PREMOLAR_CROSSBITE}
                    onChange={(data) => {
                      field.onChange(data);
                    }}
                    inputProps={{
                      controlShouldRenderValue: true
                    }}
                  />
                </div>
              </div>
            </>
          )}
        />
        <Controller
          control={control}
          name={fieldNames.extrusionElastics}
          render={({ field }) => (
            <>
              <div>
                <div className='flex space-x-4'>
                  <FormLabel
                    label={
                      isPrescription
                        ? `5. ${translate('prescription.extrusion.elastics')}`
                        : `6. ${translate('prescription.extrusion.elastics')}`
                    }
                    error={!!_.get(errors, fieldNames.extrusionElastics)}
                    name={fieldNames.extrusionElastics}
                    className='text-sm mb-2.5'
                  />
                  <InfoToolTip
                    message={translate('extrusionElastics.message')}
                    className='w-5 h-5 text-PRIMARY'
                    toolTipStyle='w-45 md:w-full'
                  />
                </div>

                <div className='w-full md:w-325px space-y-7 mr-[2rem]'>
                  <RhfSelect
                    isDisabled={isDisabled}
                    name={fieldNames.extrusionElastics}
                    options={BITE_RAMP}
                    onChange={(data) => {
                      field.onChange(data);
                    }}
                    inputProps={{
                      controlShouldRenderValue: true
                    }}
                  />
                </div>
              </div>
            </>
          )}
        />
        <Controller
          control={control}
          name={fieldNames.others}
          render={() => (
            <>
              <div>
                <RHFTextArea
                  title={isPrescription ? '6. Others' : '7. Others'}
                  labelclassName='text-sm mb-2'
                  name={fieldNames.others}
                  autogrow
                  inputProps={{
                    disabled: isDisabled,
                    placeholder: translate('settings.please_type_here')
                  }}
                  fontClass='text-sm'
                  maxgrowHeight={260}
                />
              </div>
            </>
          )}
        />
      </div>
    </div>
  );
};

export default ZenPLusPreferenceTemplate;
