import _ from 'lodash';

import {
  BothTreatmentPlans,
  NONEVALUE,
  ZenClear,
  ZenPlus
} from 'constants/common';
import { ZENSTAGE_OPTIONS } from 'constants/options';
import { REFERRALS_STEPS, valueKeyMapper } from 'constants/referral';
import {
  ARCHES_TO_TREAT,
  ATTACHMENTS_OPTIONS,
  IPR_OR_ATTACHMENT_OPTIONS,
  IPR_REFINEMENT_FORM_OPTIONS,
  MIDLINES,
  OVERBITES,
  REFINEMENT_GOALS,
  REFINEMENT_GOALS_OPTIONS,
  REFINEMENT_IPR,
  RESOLVE_POSTERIOR_OPEN_BITE_OPTIONS,
  TEETH_SELECT_OPTIONS,
  TRUTH_VALUE,
  ZENCLEAR_TPV_REFINEMENT_FORM_ARCHES_TO_TREAT,
  ZENCLEAR_TPV_REFINEMENT_FORM_ATTACHMENTS,
  ZENCLEAR_TPV_REFINEMENT_FORM_REASON_BACKEND,
  ZENCLEAR_TPV_REFINEMENT_FORM_REASON_WITH_ILL_FITTING
} from 'constants/TPVFormOptions';
import {
  BITE_RAMP,
  IPR_LOCATION,
  IPR_OPTIONS,
  PREMOLAR_CROSSBITE
} from 'constants/treatmentPlanningPreferenceTemplate';
import { FilesData } from 'types/Files.types';
import {
  AlignerProductionType,
  ToothOptionInput
} from 'types/PatientList.types';
import {
  clinicalPreferencesRefinementType,
  TreatmentSpecExternalType,
  TreatmentSpecRefinementType
} from 'types/PendingPrescription.types';
import { ReferralButton } from 'types/Referrals.types';
import { Option } from 'types/select';
import {
  Preference,
  TreatmentPLanPreferenceFormType
} from 'types/Settings.types';
import {
  ALIGNER_INFO_TYPE,
  AlignerIPRInfo,
  ClinicalPreferencesApiInput,
  TemplateFormTypes,
  TPVFormTypes,
  ZENCLEAR,
  ZenclearApiInput,
  ZENPLUS,
  ZenPlusApiInput
} from 'types/TPVForm.types';
import {
  midlineMapper,
  midlineShiftsMapper,
  overBiteValueMapper,
  PriorTreatment,
  PriorTreatmentNeededType,
  TeethExtraction,
  TreatmentPlanning,
  TreatmentSpecificationOptions,
  TreatmentSpecifications,
  TreatmentSpecType
} from 'types/TreatmentPlanning.types';
import { translate } from 'utils';

import {
  formatMidlinesShiftForForm,
  formatTeeth,
  getAlignerData,
  getArchesToTreat,
  getRefinementFormData,
  getSetAndZenStageDefaultValues,
  getTreatmentGoalsAndProcedures,
  getZenClearDataForTreatmentSpec,
  getZenPlusDataForTreatmentSpec
} from './tpvUtils';
import { treatmentPlanType } from '../components/TreatmentPlanningInstructions/TreatmentPlanningInstructions.config';
import { EnumResponse } from '../types/TreatmentPlanning.types';

type ErrorList = { id: string; label: string; field: string };

export const formatTeethsForSubmission = (
  teeths: TeethExtraction | undefined,
  instruction: string | undefined
): string | undefined => {
  if (instruction === 'NO_TEETH_TO_EXTRACT') return NONEVALUE;
  else if (instruction === 'TEETH_TO_EXTRACT') {
    if (!teeths) return NONEVALUE;
    const teethsArray = Object.entries(teeths).reduce((prev, [, value]) => {
      value?.length &&
        prev.push(
          value
            ?.map((option) => option.value)
            .sort()
            .toString()
        );
      return prev;
    }, [] as string[]);
    return teethsArray.toString();
  }
  return undefined;
};

export const formatTeethsForController = (
  teeths: string | undefined | null
): TeethExtraction | undefined => {
  if (teeths === NONEVALUE || !teeths) return undefined;
  else {
    const [rightTop, rightBottom, leftTop, leftBottom] = teeths
      .split(',')
      .reduce(
        (prev, current) => {
          const teethPostion = returnTeethPostion(current);
          prev[teethPostion].push({ value: current });
          return prev;
        },
        [[], [], [], []] as Option[][]
      );
    return { rightTop, rightBottom, leftTop, leftBottom };
  }
};

const formatIprOrAttachment = (iprOrAttachment?: string[]) => {
  return IPR_OR_ATTACHMENT_OPTIONS.filter((option: Option) =>
    iprOrAttachment?.includes(option.value)
  );
};
export const formatTeethSelection = (teeths: string | undefined) => {
  if (!teeths) return '';
  else if (teeths === TEETH_SELECT_OPTIONS.NONE)
    return TEETH_SELECT_OPTIONS.NONE;
  else return TEETH_SELECT_OPTIONS.SELECT_TEETH;
};

const returnTeethPostion = (teeth: string) => {
  switch (teeth.charAt(0)) {
    case '1':
      return 0;
    case '2':
      return 2;
    case '3':
      return 3;
    case '4':
      return 1;
    default:
      return 0;
  }
};

export const formatTeethToExtract = (
  extractTeeth?: string,
  teethtoExtract?: TeethExtraction
) => {
  if (extractTeeth === TEETH_SELECT_OPTIONS.NONE)
    return TEETH_SELECT_OPTIONS.NONE;
  if (extractTeeth === TEETH_SELECT_OPTIONS.SELECT_TEETH)
    return formatTeeth(teethtoExtract);
  if (!extractTeeth) return undefined;
};

const formatTreatmentPlanForSubmission = (
  treatmentPlanTypes: string | undefined
) => {
  const isBothSelected = treatmentPlanTypes === BothTreatmentPlans;
  if (isBothSelected)
    return treatmentPlanType
      .filter(({ value }) => value !== BothTreatmentPlans)
      ?.map(({ value }) => value);
  return treatmentPlanTypes ? [treatmentPlanTypes] : [];
};

export const getDefaultTreatmentPlanType = (
  doctorComplexity: number | null
) => {
  if (doctorComplexity && doctorComplexity < 4) return ZenClear;
  return '';
};

const getZenplusInstruction = (
  inst: string | undefined,
  fromReferral: boolean | undefined
) => {
  const instruction = fromReferral ? inst : inst || undefined;
  return instruction;
};

export const getFiles = (
  filetype: string,
  documents?: FilesData[]
): FilesData[] => {
  return documents?.filter((docs) => docs.key === filetype) || [];
};

export const formatTreatmentPlaningForSubmission = (
  isAutoSave: boolean,
  priorTreatments?: PriorTreatmentNeededType,
  treatmentSpecification?: TreatmentSpecType,
  fromReferral?: boolean,
  takeImpressions?: boolean
): TreatmentPlanning => {
  const treatmentSpecifications: TreatmentSpecifications = {
    attachmentRequired:
      treatmentSpecification?.treatmentSpecAttachments || undefined,
    crowdingResolvement:
      treatmentSpecification?.crowdingResolvement || undefined,
    iprLocation: treatmentSpecification?.iprLocation || undefined,
    teethToExtract: formatTeethsForSubmission(
      treatmentSpecification?.teethToExtract,
      treatmentSpecification?.teethExtractionInstructions
    ),
    treatmentPlanInstructions:
      treatmentSpecification?.treatmentPlanTypes === ZenClear ||
      treatmentSpecification?.treatmentPlanTypes === BothTreatmentPlans
        ? treatmentSpecification?.treatmentPlanInstructions
        : undefined,
    treatmentPlanTypes:
      formatTreatmentPlanForSubmission(
        treatmentSpecification?.treatmentPlanTypes
      ) || [],
    zenplusInstructions:
      treatmentSpecification?.treatmentPlanTypes === ZenPlus ||
      treatmentSpecification?.treatmentPlanTypes === BothTreatmentPlans
        ? getZenplusInstruction(
            treatmentSpecification?.zenplusInstructions,
            fromReferral
          )
        : undefined,
    useToothImpressions: takeImpressions || undefined,
    version: !isAutoSave ? 'V0' : undefined
  };
  const priorTreatment: PriorTreatment = {
    canPlanningProceed: priorTreatments?.canPlanningProceed ?? undefined,
    completed: priorTreatments?.completed ?? undefined,
    treatments: priorTreatments?.treatments
      ? priorTreatments?.treatments.filter((value) => value)
      : undefined
  };
  return { priorTreatment, treatmentSpecifications };
};
const getTpvPrescription = (
  isTreatmentTypeChanged: boolean,
  ClinicalPreferences: TreatmentPLanPreferenceFormType,
  treatmentSpecification: TreatmentSpecExternalType
) => {
  if (treatmentSpecification?.treatmentType === ZENCLEAR)
    return getZenClearDataForTreatmentSpec(
      isTreatmentTypeChanged,
      ClinicalPreferences,
      treatmentSpecification?.ZenClear
    );
  else if (treatmentSpecification?.treatmentType === ZENPLUS)
    return getZenPlusDataForTreatmentSpec(
      isTreatmentTypeChanged,
      ClinicalPreferences,
      treatmentSpecification?.ZenPlus
    );
  else
    return {
      //RESET case //here clinical preference is sent an empty object when the treatmentType is changed.This is only for SavePrescription.
      clinicalPreferences: {}
    };
};
const getTreatmentPlanTypes = (treatmentType?: string) => {
  if (treatmentType === ZENCLEAR) return [TPVFormTypes.ZENCLEAR];
  else if (treatmentType === ZENPLUS) return [TPVFormTypes.ZENPLUS];
  return [];
};

export const formatTreatmentPlaningForSubmissionExternal = (
  isTreatmentTypeChanged: boolean,
  isAutosave: boolean,
  treatmentSpecification: TreatmentSpecExternalType,
  ClinicalPreferences: TreatmentPLanPreferenceFormType,
  priorTreatments?: PriorTreatmentNeededType
): TreatmentPlanning => {
  const treatmentSpecifications: TreatmentSpecifications = {
    treatmentPlanTypes: getTreatmentPlanTypes(
      treatmentSpecification?.treatmentType
    ),
    tpv: getTpvPrescription(
      isTreatmentTypeChanged,
      ClinicalPreferences,
      treatmentSpecification
    ),
    version: !isAutosave ? 'V1' : undefined
  };
  const priorTreatment: PriorTreatment = {
    canPlanningProceed: priorTreatments?.canPlanningProceed ?? undefined,
    completed: priorTreatments?.completed ?? undefined,
    treatments: priorTreatments?.treatments
      ? priorTreatments?.treatments.filter((value) => value)
      : undefined
  };
  return { priorTreatment, treatmentSpecifications };
};
const getTeethExtractionInst = (teethToExtract: string | undefined) => {
  if (teethToExtract === NONEVALUE) return 'NO_TEETH_TO_EXTRACT';
  else if (teethToExtract) return 'TEETH_TO_EXTRACT';
  return '';
};

export const formatTreatmentSpecificationsForControllers = (
  treatmentSpecifications: TreatmentSpecifications | undefined,
  doctorComplexity?: number,
  isCompleted?: boolean
): TreatmentSpecType => {
  let tplan;
  if (treatmentSpecifications?.treatmentPlanTypes?.length === 2)
    tplan = BothTreatmentPlans;
  else if (doctorComplexity && doctorComplexity <= 3) tplan = ZenClear;
  else tplan = treatmentSpecifications?.treatmentPlanTypes?.[0];

  return {
    treatmentSpecAttachments:
      treatmentSpecifications?.attachmentRequired || 'IF_NECESSARY',
    crowdingResolvement:
      treatmentSpecifications?.crowdingResolvement || 'EXPANSION_WITH_IPR',
    iprLocation: treatmentSpecifications?.iprLocation || 'AS_REQUIRED',
    teethToExtract: treatmentSpecifications?.teethToExtract
      ? formatTeethsForController(treatmentSpecifications?.teethToExtract)
      : formatTeethsForController(treatmentSpecifications?.tpv?.teethToExtract),
    teethExtractionInstructions: treatmentSpecifications?.teethToExtract
      ? getTeethExtractionInst(treatmentSpecifications?.teethToExtract)
      : getTeethExtractionInst(treatmentSpecifications?.tpv?.teethToExtract),
    treatmentPlanInstructions:
      migrateNewPrescriptionData(
        TPVFormTypes.ZENCLEAR,
        treatmentSpecifications,
        isCompleted
      ) || '',

    treatmentPlanTypes: tplan,
    zenplusInstructions:
      migrateNewPrescriptionData(
        TPVFormTypes.ZENPLUS,
        treatmentSpecifications,
        isCompleted
      ) || ''
  };
};

const getTranslatedText = (value?: string) => {
  if (value && valueKeyMapper[value]) {
    return translate(valueKeyMapper[value]);
  }
  return value || '-';
};

const generateSpecialNotes = (value: string, label: string) => {
  const note = '';
  if (value && value !== '-') {
    return note + `\n${label} : ${value}`;
  }
  return '';
};

const migrateOldPrescriptionData = (
  treatmentSpecifications: TreatmentSpecifications,
  isCompleted: boolean,
  treatmentType: TPVFormTypes
) => {
  const data = treatmentSpecifications?.tpv as ZenclearApiInput;
  if (isCompleted || data?.specialNotes?.trim()) {
    return data?.specialNotes || '';
  } else {
    const attachment = getTranslatedText(
      treatmentSpecifications?.attachmentRequired
    );
    const crowdingResolvement = getTranslatedText(
      treatmentSpecifications?.crowdingResolvement
    );
    const iprLocation = getTranslatedText(treatmentSpecifications?.iprLocation);
    let treatmentInstruction = '';
    const specialNotes =
      generateSpecialNotes(attachment, 'Attachments') +
      generateSpecialNotes(crowdingResolvement, 'Resolve Crowding') +
      generateSpecialNotes(iprLocation, 'IPR Location');
    if (
      treatmentType === TPVFormTypes.ZENCLEAR &&
      treatmentSpecifications?.treatmentPlanTypes?.includes(
        treatmentType || ''
      ) &&
      treatmentSpecifications?.treatmentPlanInstructions
    ) {
      treatmentInstruction = `Treatment plan instructions: ${treatmentSpecifications?.treatmentPlanInstructions} `;
    } else if (
      treatmentType === TPVFormTypes.ZENPLUS &&
      treatmentSpecifications?.treatmentPlanTypes?.includes(
        treatmentType || ''
      ) &&
      treatmentSpecifications?.zenplusInstructions
    ) {
      treatmentInstruction = `Treatment plan instructions: ${treatmentSpecifications?.zenplusInstructions}`;
    }
    return `${treatmentInstruction} ${specialNotes}`.trim();
  }
};

const getFormattedRefinementGoals = (refinementGoals?: ToothOptionInput[]) => {
  const closeResidualSpace: string[] = [];
  const resolvePosteriorOpenBite: string[] = [];
  const info: string[] = [];
  refinementGoals?.forEach((item) => {
    if (
      item?.name === 'Alignment' ||
      item?.name === 'Resolve Heavy Anterior Contacts'
    )
      info.push(`Refinement goal is ${item?.name}`);

    if (item?.name === 'Close Residual Space') {
      item?.value?.forEach((toothData) => {
        closeResidualSpace.push(`${toothData?.position}[${toothData?.value}]`);
      });
      if (closeResidualSpace?.length > 0)
        info.push(
          `Refinement goal is Close Residual Space: ${closeResidualSpace.join(
            ','
          )}`
        );
      else info.push(`Refinement goal is Close Residual Space`);
    }

    if (item?.name === 'Resolve posterior open bite') {
      item?.value?.forEach((toothData) => {
        if (toothData?.name === 'See resulting plan')
          resolvePosteriorOpenBite.push(`${toothData?.name}`);
        else {
          if (toothData?.name && toothData?.value === '')
            resolvePosteriorOpenBite.push(`${toothData?.name}`);
          else if (toothData?.name && !!toothData?.value)
            resolvePosteriorOpenBite.push(
              `${toothData?.name}-${toothData?.value}`
            );
        }
      });
      if (resolvePosteriorOpenBite?.length > 0)
        info.push(
          `Refinement goal is Resolve posterior open bite : ${resolvePosteriorOpenBite.join(
            ','
          )}`
        );
      else info.push(`Refinement goal is Resolve posterior open bite`);
    }
  });
  return info?.length > 0 ? `\n\n${info.join('\n')}` : '';
};

const getFormattedIprInfo = (
  treatmentSpecifications?: TreatmentSpecifications
) => {
  const iprInfo = treatmentSpecifications?.tpv?.iprInfo;
  const iprInfoText = treatmentSpecifications?.tpv?.iprInfoText;
  const value: string[] = [];
  iprInfo?.value?.forEach(
    (item: { value: string; position: string; setNumber: string }) => {
      if (item?.value || item?.position || item?.setNumber)
        value.push(
          `[value:${item?.value},position:${item?.position},set Number:${item?.setNumber}]`
        );
    }
  );
  if (iprInfo?.name === 'IPR performed as prescribed')
    return `\n\nIPR performed:IPR performed as prescribed`;
  if (iprInfo?.name === 'IPR not performed as prescribed') {
    if (!iprInfoText) {
      if (value?.length > 0)
        return `\n\nIPR performed:IPR not performed as prescribed-${value?.join(
          ','
        )}`;
      else return `\n\nIPR performed:IPR not performed as prescribed`;
    } else {
      return `\n\nIPR performed:IPR not performed as prescribed\nIPR info text:${iprInfoText}`;
    }
  }
  return '';
};

const getFormattedAlignerInfo = (
  predecessorTPVData: AlignerIPRInfo,
  alignerInfo?: { name: string; value: { name: string; value: string }[] }[]
) => {
  const alignerData: string[] = [];
  let alignerValueData: string[] = [];
  alignerInfo?.map((item) => {
    alignerValueData = [];
    item?.value?.map((itemValue) => {
      if (itemValue?.name === 'Zenstage') {
        if (itemValue?.value)
          alignerValueData.push(`zenstage number ${itemValue?.value || '-'}`);
        else alignerValueData.push(`zenstage`);
      } else if (itemValue?.name === 'Set') {
        if (itemValue?.value)
          alignerValueData.push(
            `set number ${itemValue?.value || '-'}/${
              predecessorTPVData?.alignerInfo?.setAlignerCount
            }`
          );
        else alignerValueData.push(`set`);
      }
    });
    alignerData.push(`${item?.name} ${alignerValueData?.join(',')}`);
  });
  return alignerData?.length > 0
    ? `\n\nPatient is currently on:\n${alignerData.join('\n')}`
    : '';
};

const getFormattedTreatmentGoals = (
  isZenPlus: boolean,
  treatmentGoals?: {
    name: string;
    procedures?: { name: string; value?: { name: string; value: string }[] }[];
  }[]
) => {
  const procedures: string[] = [];
  const procedureSubOptions: string[] = [];
  let message;
  treatmentGoals?.forEach((goal) => {
    if (goal?.name === 'SPACE_CLOSURE')
      message = `Treatment Goals: Space Closure`;
    else {
      goal?.procedures?.forEach((item) => {
        procedures.push(item?.name);
        if (item?.name === 'DISTALIZATION') {
          item?.value?.forEach((subOption) => {
            if (subOption?.name && !subOption?.value)
              procedureSubOptions?.push(`${subOption?.name}`);
            else if (!!subOption?.name && !!subOption?.value)
              procedureSubOptions?.push(
                `${subOption?.name}- ${subOption?.value}`
              );
          });
        }
      });

      if (goal?.name === 'ALIGNMENT') {
        if (isZenPlus)
          message = `Treatment Goals: Alignment-${procedures.join(',')}`;
        else message = `Treatment Goals: Alignment`;
      }
      if (goal?.name === 'RETRACTION') {
        if (isZenPlus) {
          if (procedures?.length > 0 && procedureSubOptions?.length > 0)
            message = `Treatment Goals: Retraction-${procedures.join(
              ','
            )}:${procedureSubOptions.join('|')}`;
          else if (procedures?.length > 0 && procedureSubOptions?.length === 0)
            message = `Treatment Goals: Retraction-${procedures.join(',')}`;
          else message = `Treatment Goals: Retraction`;
        } else message = `Treatment Goals: Retraction`;
      }
    }
  });
  return message ? `\n\n${message}` : '';
};

const getFormattedMidlines = (midlines?: string, midlineShifts?: string[]) => {
  const shifts: string[] = [];
  if (midlines) {
    if (midlines === 'IMPROVE') {
      midlineShifts?.forEach((item) => {
        shifts?.push(midlineShiftsMapper[item]);
      });
      if (shifts?.length > 0)
        return `\n\nMidlines: ${midlineMapper[midlines || '']}-${shifts.join(
          ','
        )}`;
      else return `\n\nMidlines: ${midlineMapper[midlines || '']}`;
    }
    return `\n\nMidlines: ${midlineMapper[midlines || '']}`;
  }
  return '';
};

const getFormattedProsthesis = (
  prosthesis?: string,
  prosthesisOptions?: string[],
  prosthesisIprToAvoid?: string,
  prosthesisAttachmentToAvoid?: string
) => {
  const restorations: string[] = [];
  if (prosthesis === 'NO') return `\n\nProsthesis: None`;
  else if (prosthesis === 'YES') {
    if (prosthesisOptions?.includes('IPR to avoid')) {
      if (prosthesisIprToAvoid)
        restorations.push(`IPR to avoid: ${prosthesisIprToAvoid || '-'}`);
      else restorations.push(`IPR to avoid`);
    }
    if (prosthesisOptions?.includes('Attachment to avoid')) {
      if (prosthesisAttachmentToAvoid)
        restorations.push(
          `Attachment to avoid: ${prosthesisAttachmentToAvoid || '-'}`
        );
      else restorations.push(`Attachment to avoid`);
    }
    if (restorations?.length > 0)
      return `\n\nProsthesis: Yes-${restorations?.join(',')}`;
    else return `\n\nProsthesis: Yes`;
  }
  return '';
};
const getFormattedText = (label?: string, value?: any) => {
  if (value) return `\n\n${label} : ${value}`;
  else return '';
};
const getFormattedToothMovementRestrictions = (teethToMove?: string) => {
  if (!!teethToMove && teethToMove !== 'None')
    return `\n\nTooth movement restrictions: These teeth should not be moved - ${teethToMove}`;
  else if (teethToMove === 'None')
    return '\n\nTooth movement restrictions: None';
  return '';
};
const getFormattedArchesToTreat = (archesToTreat?: string[]) => {
  if (archesToTreat?.length === 1 && archesToTreat[0] === 'UPPER')
    return 'Arches to treat:Upper - passive aligners for opposing arch';
  else if (archesToTreat?.length === 1 && archesToTreat[0] === 'LOWER')
    return 'Arches to treat:Lower - passive aligners for opposing arch';
  else if (archesToTreat?.length === 2) return 'Arches to treat:Both';
  return '';
};
export const migrateNewRefinementPrescriptionData = (
  predecessorTPVData: AlignerIPRInfo,
  treatmentType: TPVFormTypes,
  treatmentSpecifications?: TreatmentSpecifications,
  isCompleted?: boolean,
  isSumbissionReasonAndAlignerInfoNeeded?: boolean
) => {
  const isZenClear = treatmentType === planTypeCode.ZENCLEAR;
  const isZenPlus = treatmentType === planTypeCode.ZEN_PLUS;
  const tpv = treatmentSpecifications?.tpv;

  if (
    isZenClear &&
    (isCompleted || !!treatmentSpecifications?.treatmentPlanInstructions)
  )
    return treatmentSpecifications?.treatmentPlanInstructions;
  else if (
    isZenPlus &&
    (isCompleted || !!treatmentSpecifications?.zenplusInstructions)
  )
    return treatmentSpecifications?.zenplusInstructions;
  else {
    return `${getFormattedArchesToTreat(tpv?.archesToTreat)}${getFormattedText(
      'Attachments',
      tpv?.attachments
    )}${getFormattedText(
      'Special Notes',
      tpv?.specialNotes
    )}${getFormattedRefinementGoals(tpv?.refinementGoals)}${getFormattedIprInfo(
      treatmentSpecifications
    )}${
      isSumbissionReasonAndAlignerInfoNeeded
        ? `${getFormattedText(
            'Refinement Submission Reason',
            tpv?.submissionReason
          )}${getFormattedAlignerInfo(predecessorTPVData, tpv?.alignerInfo)}`
        : ''
    }`;
  }
};
export const migrateNewPrescriptionData = (
  treatmentType: TPVFormTypes,
  treatmentSpecifications?: TreatmentSpecifications,
  isCompleted?: boolean
) => {
  const isZenClear = treatmentType === TPVFormTypes.ZENCLEAR;
  const isZenPlus = treatmentType === TPVFormTypes.ZENPLUS;
  const tpv = treatmentSpecifications?.tpv;

  if (
    isZenClear &&
    (isCompleted || !!treatmentSpecifications?.treatmentPlanInstructions)
  )
    return treatmentSpecifications?.treatmentPlanInstructions;
  else if (
    isZenPlus &&
    (isCompleted || !!treatmentSpecifications?.zenplusInstructions)
  )
    return treatmentSpecifications?.zenplusInstructions;
  else {
    if (
      isZenClear &&
      treatmentSpecifications?.treatmentPlanTypes?.includes(treatmentType)
    ) {
      const treatmentPlanInstructions = `${getFormattedArchesToTreat(
        tpv?.archesToTreat
      )}${getFormattedToothMovementRestrictions(
        tpv?.toothMovementRestrictions || ''
      )}${getFormattedTreatmentGoals(
        false,
        tpv?.treatmentGoals
      )}${getFormattedText(
        'Special Notes',
        tpv?.specialNotes
      )}${getFormattedProsthesis(
        tpv?.prosthesis,
        tpv?.prosthesisOptions,
        tpv?.prosthesisIprToAvoid,
        tpv?.prosthesisAttachmentToAvoid
      )}`;
      return treatmentPlanInstructions;
    }
    if (
      isZenPlus &&
      treatmentSpecifications?.treatmentPlanTypes?.includes(treatmentType)
    ) {
      const zenplusInstructions = `${getFormattedArchesToTreat(
        tpv?.archesToTreat
      )}${getFormattedToothMovementRestrictions(
        tpv?.toothMovementRestrictions || ''
      )}${getFormattedText(
        'Overbite',
        translate(overBiteValueMapper[tpv?.overbite || ''])
      )}${getFormattedText(
        'Special Notes',
        tpv?.specialNotes
      )}${getFormattedTreatmentGoals(
        true,
        tpv?.treatmentGoals
      )}${getFormattedMidlines(
        tpv?.midlines,
        tpv?.midlinesShifts
      )}${getFormattedProsthesis(
        tpv?.prosthesis,
        tpv?.prosthesisOptions,
        tpv?.prosthesisIprToAvoid,
        tpv?.prosthesisAttachmentToAvoid
      )}`;
      return zenplusInstructions;
    }
  }
};
export const formatTreatmentSpecificationsForExternal = (
  treatmentSpecifications?: TreatmentSpecifications,
  hideLiveTranslation?: boolean,
  isCompleted = false
): TreatmentSpecExternalType => {
  let data;
  if (treatmentSpecifications) {
    data = treatmentSpecifications?.tpv as ZenclearApiInput;
    if (treatmentSpecifications?.treatmentPlanTypes?.[0] === 'ZENCLEAR') {
      return {
        ZenClear: {
          treatmentGoals: {
            treatmentGoals: data?.treatmentGoals?.map((item: any) => item.name)
          },

          archesToTreat: getArchesToTreat(data?.archesToTreat),
          toothMovementRestrictions: {
            toothMovementRestrictions: data?.toothMovementRestrictions
              ? formatTeethSelection(data.toothMovementRestrictions)
              : TEETH_SELECT_OPTIONS.NONE,
            teethNotToMove: formatTeethsForController(
              data?.toothMovementRestrictions
            )
          },
          prosthesis: {
            iprOrAttachment: formatIprOrAttachment(data?.prosthesisOptions),
            prosthesis: data?.prosthesis ? data.prosthesis : TRUTH_VALUE.NO,
            prosthesisIprToAvoid: formatTeethsForController(
              data?.prosthesisIprToAvoid
            ),
            prosthesisAttachmentToAvoid: formatTeethsForController(
              data?.prosthesisAttachmentToAvoid
            )
          },
          extractTeeth: {
            extractTeeth:
              data?.teethToExtract || treatmentSpecifications?.teethToExtract
                ? formatTeethSelection(
                    data?.teethToExtract ||
                      treatmentSpecifications?.teethToExtract
                  )
                : TEETH_SELECT_OPTIONS.NONE,
            teethToExtract: formatTeethsForController(
              data?.teethToExtract || treatmentSpecifications?.teethToExtract
            )
          },
          specialNotes: migrateOldPrescriptionData(
            treatmentSpecifications,
            isCompleted,
            TPVFormTypes.ZENCLEAR
          ),
          translatedSpecialNotes: hideLiveTranslation
            ? ''
            : data?.translatedSpecialNotes
        },
        ZenPlus: {
          treatmentGoals: undefined,

          archesToTreat: ARCHES_TO_TREAT.BOTH,
          toothMovementRestrictions: {
            teethNotToMove: undefined,
            toothMovementRestrictions: TEETH_SELECT_OPTIONS.NONE
          },
          prosthesis: {
            iprOrAttachment: undefined,
            prosthesis: TRUTH_VALUE.NO,
            prosthesisIprToAvoid: undefined,
            prosthesisAttachmentToAvoid: undefined
          },
          extractTeeth: {
            extractTeeth: TEETH_SELECT_OPTIONS.NONE,
            teethToExtract: undefined
          },

          specialNotes: isCompleted
            ? ''
            : treatmentSpecifications?.zenplusInstructions || '',

          overbite: OVERBITES.SHOW_RESULTING,
          midlines: {
            midlines: MIDLINES.SHOW_RESULTING,
            shiftUpper: undefined,
            shiftLower: undefined,
            shiftUpperPosition: undefined,
            shiftLowerPosition: undefined
          }
        },
        treatmentType: ZENCLEAR
      };
    } else if (
      treatmentSpecifications?.treatmentPlanTypes?.[0] === 'ZENCLEAR_PLUS'
    ) {
      data = treatmentSpecifications?.tpv as ZenPlusApiInput;
      const { shiftLower, shiftUpper } = formatMidlinesShiftForForm(
        data?.midlinesShifts
      );
      const goalsAndProcedures = getTreatmentGoalsAndProcedures(
        data?.treatmentGoals
      );
      return {
        ZenPlus: {
          treatmentGoals: {
            treatmentGoals: goalsAndProcedures.goals,
            treatmentGoalsProcedures:
              goalsAndProcedures.treatmentGoalsProcedure,
            proceduresInnerFields: goalsAndProcedures.proceduresInnerFields,
            upperLowerProcedures: goalsAndProcedures.upperLowerProcedures
          },

          archesToTreat: getArchesToTreat(data?.archesToTreat),
          toothMovementRestrictions: {
            teethNotToMove: formatTeethsForController(
              data?.toothMovementRestrictions
            ),
            toothMovementRestrictions: data?.toothMovementRestrictions
              ? formatTeethSelection(data.toothMovementRestrictions)
              : TEETH_SELECT_OPTIONS.NONE
          },
          prosthesis: {
            iprOrAttachment: formatIprOrAttachment(data?.prosthesisOptions),

            prosthesis: data?.prosthesis ? data.prosthesis : TRUTH_VALUE.NO,
            prosthesisIprToAvoid: formatTeethsForController(
              data?.prosthesisIprToAvoid
            ),
            prosthesisAttachmentToAvoid: formatTeethsForController(
              data?.prosthesisAttachmentToAvoid
            )
          },
          extractTeeth: {
            extractTeeth:
              data?.teethToExtract || treatmentSpecifications?.teethToExtract
                ? formatTeethSelection(
                    data?.teethToExtract ||
                      treatmentSpecifications?.teethToExtract
                  )
                : TEETH_SELECT_OPTIONS.NONE,
            teethToExtract: formatTeethsForController(
              data?.teethToExtract || treatmentSpecifications?.teethToExtract
            )
          },

          specialNotes: migrateOldPrescriptionData(
            treatmentSpecifications,
            isCompleted,
            TPVFormTypes.ZENPLUS
          ),
          translatedSpecialNotes: hideLiveTranslation
            ? ''
            : data?.translatedSpecialNotes,
          overbite: data?.overbite ? data.overbite : OVERBITES.SHOW_RESULTING,
          midlines: {
            midlines: data?.midlines ? data.midlines : MIDLINES.SHOW_RESULTING,
            shiftUpper: !!shiftUpper,
            shiftLower: !!shiftLower,
            shiftUpperPosition: shiftUpper as string,
            shiftLowerPosition: shiftLower as string
          }
        },
        ZenClear: {
          treatmentGoals: undefined,
          archesToTreat: ARCHES_TO_TREAT.BOTH,
          toothMovementRestrictions: {
            toothMovementRestrictions: TEETH_SELECT_OPTIONS.NONE,
            teethNotToMove: undefined
          },
          prosthesis: {
            iprOrAttachment: undefined,
            prosthesis: TRUTH_VALUE.NO,
            prosthesisIprToAvoid: undefined,
            prosthesisAttachmentToAvoid: undefined
          },
          extractTeeth: {
            extractTeeth: TEETH_SELECT_OPTIONS.NONE,
            teethToExtract: undefined
          },

          specialNotes: isCompleted
            ? ''
            : treatmentSpecifications?.treatmentPlanInstructions || ''
        },
        treatmentType: ZENPLUS
      };
    }
  }
  return {
    ZenClear: {
      treatmentGoals: undefined,
      archesToTreat: ARCHES_TO_TREAT.BOTH,
      toothMovementRestrictions: {
        toothMovementRestrictions: TEETH_SELECT_OPTIONS.NONE,
        teethNotToMove: undefined
      },
      prosthesis: {
        iprOrAttachment: undefined,
        prosthesis: TRUTH_VALUE.NO,
        prosthesisIprToAvoid: undefined,
        prosthesisAttachmentToAvoid: undefined
      },
      extractTeeth: {
        extractTeeth: TEETH_SELECT_OPTIONS.NONE,
        teethToExtract: undefined
      },

      specialNotes: undefined
    },
    ZenPlus: {
      treatmentGoals: undefined,

      archesToTreat: ARCHES_TO_TREAT.BOTH,
      toothMovementRestrictions: {
        teethNotToMove: undefined,
        toothMovementRestrictions: TEETH_SELECT_OPTIONS.NONE
      },
      prosthesis: {
        iprOrAttachment: undefined,
        prosthesis: TRUTH_VALUE.NO,
        prosthesisIprToAvoid: undefined,
        prosthesisAttachmentToAvoid: undefined
      },
      extractTeeth: {
        extractTeeth: TEETH_SELECT_OPTIONS.NONE,
        teethToExtract: undefined
      },
      specialNotes: undefined,
      overbite: OVERBITES.SHOW_RESULTING,
      midlines: {
        midlines: MIDLINES.SHOW_RESULTING,
        shiftUpper: undefined,
        shiftLower: undefined,
        shiftUpperPosition: undefined,
        shiftLowerPosition: undefined
      }
    },
    treatmentType: undefined
  };
};
export const isClinicalPreferencesDefined = (
  clinicalPreference?: ClinicalPreferencesApiInput,
  treatmentPlanType?: TPVFormTypes[] | string[],
  isRefinement?: boolean
) => {
  const isZenClearFieldsDefined = !(
    clinicalPreference?.iprSize === null &&
    clinicalPreference?.iprLocation === null
  );
  const isZenPlusFieldsDefined = !(
    clinicalPreference?.iprSize === null &&
    clinicalPreference?.iprLocation === null &&
    clinicalPreference?.biteRamps === null &&
    clinicalPreference?.premolarCrossbite === null &&
    clinicalPreference?.extrusionElastics === null
  );
  if (clinicalPreference === undefined || clinicalPreference === null)
    return false;
  if (
    (treatmentPlanType?.[0] === 'ZENCLEAR' &&
      clinicalPreference !== undefined) ||
    isRefinement
  ) {
    return isZenClearFieldsDefined;
  }
  if (treatmentPlanType?.[0] === 'ZENCLEAR_PLUS' && !isRefinement) {
    return isZenPlusFieldsDefined;
  }
};
export const setClinicalPreferenceFromPreferenceTemplate = (
  preferenceTemplateData: Preference,
  isRefinement?: boolean,
  isZenPlusRefinement?: boolean
) => {
  const iprSizeZenClear = IPR_OPTIONS.filter((option: Option) =>
    preferenceTemplateData?.ZenClear?.iprSize?.includes(option.value)
  );
  const iprLocationZenClear = IPR_LOCATION.filter((option: Option) =>
    preferenceTemplateData?.ZenClear?.iprLocation?.includes(option.value)
  );
  const iprSizeZenPlusRefinement = IPR_OPTIONS.filter((option: Option) =>
    preferenceTemplateData?.ZenPlusRefinement?.iprSize?.includes(option.value)
  );
  const iprLocationZenPlusRefinement = IPR_LOCATION.filter((option: Option) =>
    preferenceTemplateData?.ZenPlusRefinement?.iprLocation?.includes(
      option.value
    )
  );
  const iprSizeZenClearRefinement = IPR_OPTIONS.filter((option: Option) =>
    preferenceTemplateData?.ZenClearRefinement?.iprSize?.includes(option.value)
  );
  const iprLocationZenClearRefinement = IPR_LOCATION.filter((option: Option) =>
    preferenceTemplateData?.ZenClearRefinement?.iprLocation?.includes(
      option.value
    )
  );
  const iprSizeZenPlus = IPR_OPTIONS.filter((option: Option) =>
    preferenceTemplateData?.ZenPlus?.iprSize?.includes(option.value)
  );
  const iprLocationZenPlus = IPR_LOCATION.filter((option: Option) =>
    preferenceTemplateData?.ZenPlus?.iprLocation?.includes(option.value)
  );
  const biteRampZenPlus = BITE_RAMP.filter(
    (option: Option) =>
      preferenceTemplateData?.ZenPlus?.biteRamps === option.value
  );
  const crossbiteZenPlus = PREMOLAR_CROSSBITE.filter(
    (option: Option) =>
      preferenceTemplateData?.ZenPlus?.premolarCrossbite === option.value
  );
  const elasticsZenPlus = BITE_RAMP.filter(
    (option: Option) =>
      preferenceTemplateData?.ZenPlus?.extrusionElastics === option.value
  );
  if (isRefinement) {
    if (isZenPlusRefinement && preferenceTemplateData?.ZenPlusRefinement)
      return {
        Refinement: {
          iprSize: iprSizeZenPlusRefinement,
          iprLocation: iprLocationZenPlusRefinement,
          others: preferenceTemplateData.ZenPlusRefinement.others
        }
      };
    else if (!isZenPlusRefinement && preferenceTemplateData?.ZenClearRefinement)
      return {
        Refinement: {
          iprSize: iprSizeZenClearRefinement,
          iprLocation: iprLocationZenClearRefinement,
          others: preferenceTemplateData.ZenClearRefinement.others
        }
      };
    else
      return {
        Refinement: undefined
      };
  } else if (
    !!preferenceTemplateData?.ZenClear &&
    !preferenceTemplateData?.ZenPlus &&
    !isRefinement
  ) {
    return {
      ZenClear: {
        iprSize: iprSizeZenClear,
        iprLocation: iprLocationZenClear,
        others: preferenceTemplateData?.ZenClear?.others
      },
      ZenPlus: undefined,
      treatmentPlanType: undefined
    };
  } else if (
    !!preferenceTemplateData?.ZenPlus &&
    !preferenceTemplateData?.ZenClear &&
    !isRefinement
  ) {
    return {
      ZenPlus: {
        iprSize: iprSizeZenPlus,
        iprLocation: iprLocationZenPlus,
        biteRamps: biteRampZenPlus[0],
        premolarCrossbite: crossbiteZenPlus[0],
        extrusionElastics: elasticsZenPlus[0],
        others: preferenceTemplateData?.ZenPlus?.others
      },
      ZenClear: undefined,
      treatmentPlanType: undefined
    };
  }
  return {
    ZenClear: {
      iprSize: iprSizeZenClear,
      iprLocation: iprLocationZenClear,
      others: preferenceTemplateData?.ZenClear?.others
    },
    ZenPlus: {
      iprSize: iprSizeZenPlus,
      iprLocation: iprLocationZenPlus,
      biteRamps: biteRampZenPlus[0],
      premolarCrossbite: crossbiteZenPlus[0],
      extrusionElastics: elasticsZenPlus[0],
      others: preferenceTemplateData?.ZenPlus?.others
    },
    treatmentPlanType: undefined
  };
};

//function to set Default value for clinicalPreference under PendingPrescription
export const formatClinicalPreferencesForExternal = (
  treatmentSpecifications?: TreatmentSpecifications,
  preferenceTemplateData?: Preference,
  isRefinement?: boolean,
  isZenPlusRefinement?: boolean
): TreatmentPLanPreferenceFormType => {
  let isPreferenceDataDefined;
  if (
    treatmentSpecifications?.tpv === null ||
    !treatmentSpecifications?.tpv ||
    !treatmentSpecifications?.tpv?.clinicalPreferences ||
    treatmentSpecifications?.tpv?.clinicalPreferences === null
  )
    isPreferenceDataDefined = false;
  else {
    isPreferenceDataDefined = isClinicalPreferencesDefined(
      treatmentSpecifications?.tpv?.clinicalPreferences,
      treatmentSpecifications?.treatmentPlanTypes,
      isRefinement
    );
  }
  if (isPreferenceDataDefined) {
    //here the clinical preference data is being set from the TpvData
    const iprSize = IPR_OPTIONS.filter((option: Option) =>
      treatmentSpecifications?.tpv?.clinicalPreferences?.iprSize?.includes(
        option.value
      )
    );
    const iprLocation = IPR_LOCATION.filter((option: Option) =>
      treatmentSpecifications?.tpv?.clinicalPreferences?.iprLocation?.includes(
        option.value
      )
    );
    const biteRamp = BITE_RAMP.filter(
      (option: Option) =>
        treatmentSpecifications?.tpv?.clinicalPreferences?.biteRamps ===
        option.value
    );
    const crossbite = PREMOLAR_CROSSBITE.filter(
      (option: Option) =>
        treatmentSpecifications?.tpv?.clinicalPreferences?.premolarCrossbite ===
        option.value
    );
    const elastics = BITE_RAMP.filter(
      (option: Option) =>
        treatmentSpecifications?.tpv?.clinicalPreferences?.extrusionElastics ===
        option.value
    );
    if (isRefinement) {
      return {
        Refinement: {
          iprSize: iprSize,
          iprLocation: iprLocation,
          others: treatmentSpecifications?.tpv?.clinicalPreferences?.others
        }
      };
    } else if (
      treatmentSpecifications?.treatmentPlanTypes?.[0] === 'ZENCLEAR' &&
      !isRefinement
    ) {
      const iprSizeZenPlus = IPR_OPTIONS.filter((option: Option) =>
        preferenceTemplateData?.ZenPlus?.iprSize?.includes(option.value)
      );
      const iprLocationZenPlus = IPR_LOCATION.filter((option: Option) =>
        preferenceTemplateData?.ZenPlus?.iprLocation?.includes(option.value)
      );
      const biteRampZenPlus = BITE_RAMP.filter(
        (option: Option) =>
          preferenceTemplateData?.ZenPlus?.biteRamps === option.value
      );
      const crossbiteZenPlus = PREMOLAR_CROSSBITE.filter(
        (option: Option) =>
          preferenceTemplateData?.ZenPlus?.premolarCrossbite === option.value
      );
      const elasticsZenPlus = BITE_RAMP.filter(
        (option: Option) =>
          preferenceTemplateData?.ZenPlus?.extrusionElastics === option.value
      );

      return {
        ZenClear: {
          iprSize: iprSize,
          iprLocation: iprLocation,
          others: treatmentSpecifications?.tpv?.clinicalPreferences?.others
        },
        ZenPlus: {
          iprSize: iprSizeZenPlus,
          iprLocation: iprLocationZenPlus,
          biteRamps: biteRampZenPlus?.[0],
          premolarCrossbite: crossbiteZenPlus?.[0],
          extrusionElastics: elasticsZenPlus?.[0],
          others: preferenceTemplateData?.ZenPlus?.others
        },
        treatmentPlanType: ZENCLEAR
      };
    } else if (
      treatmentSpecifications?.treatmentPlanTypes?.[0] === 'ZENCLEAR_PLUS' &&
      !isRefinement
    ) {
      const iprSizeZenClear = IPR_OPTIONS.filter((option: Option) =>
        preferenceTemplateData?.ZenClear?.iprSize?.includes(option.value)
      );
      const iprLocationZenClear = IPR_LOCATION.filter((option: Option) =>
        preferenceTemplateData?.ZenClear?.iprLocation?.includes(option.value)
      );
      return {
        ZenPlus: {
          iprSize: iprSize,
          iprLocation: iprLocation,
          biteRamps: biteRamp?.[0],
          premolarCrossbite: crossbite?.[0],
          extrusionElastics: elastics?.[0],
          others: treatmentSpecifications?.tpv?.clinicalPreferences?.others
        },
        ZenClear: {
          iprSize: iprSizeZenClear,
          iprLocation: iprLocationZenClear,
          others: preferenceTemplateData?.ZenClear?.others
        },
        treatmentPlanType: ZENPLUS
      };
    }
  } else if (!isPreferenceDataDefined && !!preferenceTemplateData) {
    //here the clinical preference data is being set from the preferenceTemplateData
    return setClinicalPreferenceFromPreferenceTemplate(
      preferenceTemplateData,
      isRefinement,
      isZenPlusRefinement
    );
  }
  return {
    //in this there is no clinicalData in TpvData and preferenceTemplate
    ZenClear: undefined,
    ...(!isRefinement && { ZenPlus: undefined }),
    ...(!isRefinement && { treatmentPlanType: undefined })
  };
};
export const getClinicalPreferenceSettingsPage = (
  preferenceTemplateData: Preference | undefined
) => {
  const iprSizeZenPlus = IPR_OPTIONS.filter((option: Option) =>
    preferenceTemplateData?.ZenPlus?.iprSize?.includes(option.value)
  );
  const iprLocationZenPlus = IPR_LOCATION.filter((option: Option) =>
    preferenceTemplateData?.ZenPlus?.iprLocation?.includes(option.value)
  );
  const biteRampZenPlus = BITE_RAMP.filter(
    (option: Option) =>
      preferenceTemplateData?.ZenPlus?.biteRamps === option.value
  );
  const crossbiteZenPlus = PREMOLAR_CROSSBITE.filter(
    (option: Option) =>
      preferenceTemplateData?.ZenPlus?.premolarCrossbite === option.value
  );
  const elasticsZenPlus = BITE_RAMP.filter(
    (option: Option) =>
      preferenceTemplateData?.ZenPlus?.extrusionElastics === option.value
  );
  const iprSizeZenClear = IPR_OPTIONS.filter((option: Option) =>
    preferenceTemplateData?.ZenClear?.iprSize?.includes(option.value)
  );
  const iprLocationZenClear = IPR_LOCATION.filter((option: Option) =>
    preferenceTemplateData?.ZenClear?.iprLocation?.includes(option.value)
  );
  const iprSizeZenPlusRefinement = IPR_OPTIONS.filter((option: Option) =>
    preferenceTemplateData?.ZenPlusRefinement?.iprSize?.includes(option.value)
  );
  const iprLocationZenPlusRefinement = IPR_LOCATION.filter((option: Option) =>
    preferenceTemplateData?.ZenPlusRefinement?.iprLocation?.includes(
      option.value
    )
  );
  const iprSizeZenClearRefinement = IPR_OPTIONS.filter((option: Option) =>
    preferenceTemplateData?.ZenClearRefinement?.iprSize?.includes(option.value)
  );
  const iprLocationZenClearRefinement = IPR_LOCATION.filter((option: Option) =>
    preferenceTemplateData?.ZenClearRefinement?.iprLocation?.includes(
      option.value
    )
  );
  return {
    ZenClear: preferenceTemplateData?.ZenClear
      ? {
          iprLocation: iprLocationZenClear,
          iprSize: iprSizeZenClear,
          others: preferenceTemplateData?.ZenClear?.others
        }
      : undefined,
    ZenPlus: preferenceTemplateData?.ZenPlus
      ? {
          iprSize: iprSizeZenPlus,
          iprLocation: iprLocationZenPlus,
          biteRamps: biteRampZenPlus?.[0],
          premolarCrossbite: crossbiteZenPlus?.[0],
          extrusionElastics: elasticsZenPlus?.[0],
          others: preferenceTemplateData?.ZenPlus?.others
        }
      : undefined,
    ZenPlusRefinement: preferenceTemplateData?.ZenPlusRefinement
      ? {
          iprLocation: iprLocationZenPlusRefinement,
          iprSize: iprSizeZenPlusRefinement,
          others: preferenceTemplateData?.ZenPlusRefinement?.others
        }
      : undefined,
    ZenClearRefinement: preferenceTemplateData?.ZenClearRefinement
      ? {
          iprLocation: iprLocationZenClearRefinement,
          iprSize: iprSizeZenClearRefinement,
          others: preferenceTemplateData?.ZenClearRefinement?.others
        }
      : undefined,
    treatmentPlanType: TemplateFormTypes.ZenClear
  };
};
export const planTypeCode = {
  ZEN_PLUS: 'ZENCLEAR_PLUS',
  ZENCLEAR: 'ZENCLEAR'
};

export const getTreatmentSpecificationDetails = (
  options: TreatmentSpecificationOptions,
  treatmentPlans: EnumResponse[],
  treatmentSpecifications: TreatmentSpecifications | undefined
): {
  attachmentReuired: string | undefined;
  iprLocation: string | undefined;
  crowdingResolvement: string | undefined;
  planType: string;
  planInstructionEnabled: Record<string, boolean>;
} => {
  const attachmentReuired = options?.getAttachementOptions?.find(
    ({ value }) => value === treatmentSpecifications?.attachmentRequired
  )?.label;
  const iprLocation = options?.getIPRLocations?.find(
    ({ value }) => value === treatmentSpecifications?.iprLocation
  )?.label;
  const crowdingResolvement = options?.getCrowdingTreatmentOptions?.find(
    ({ value }) => value === treatmentSpecifications?.crowdingResolvement
  )?.label;
  const planType = treatmentPlans
    ?.filter(({ value }) =>
      treatmentSpecifications?.treatmentPlanTypes?.find(
        (plan) => plan === value
      )
    )
    ?.map(({ label }) => translate(label))
    ?.join(', ');
  const planInstructionEnabled = {
    [planTypeCode.ZENCLEAR]:
      !!treatmentSpecifications?.treatmentPlanTypes?.some(
        (plan) => plan === planTypeCode.ZENCLEAR
      ),
    [planTypeCode.ZEN_PLUS]:
      !!treatmentSpecifications?.treatmentPlanTypes?.some(
        (plan) => plan === planTypeCode.ZEN_PLUS
      )
  };
  return {
    attachmentReuired,
    iprLocation,
    crowdingResolvement,
    planType,
    planInstructionEnabled
  };
};

const getOtherTreatmentValue = (
  treatments: string[] | undefined,
  options: EnumResponse[] | undefined
) =>
  treatments?.find((treatment) =>
    options?.every(({ value }) => value !== treatment)
  );

export const getPriorTreatment = (
  options: EnumResponse[] | undefined,
  treatments: string[] | undefined
): string | undefined => {
  if (!treatments) return undefined;
  if (treatments?.length === 0) return translate('prescriptions.none');
  const otherTreatment = getOtherTreatmentValue(treatments, options);
  const treatmentVlaues = options
    ?.filter(({ value }) =>
      treatments?.find((treatment) => treatment === value)
    )
    ?.map(({ label }) => translate(label))
    ?.join(',');
  const finalList: string | undefined =
    treatmentVlaues === ''
      ? otherTreatment
      : `${treatmentVlaues}, ${otherTreatment}`;
  return otherTreatment ? finalList : treatmentVlaues;
};

export const checkIfAnyTeethSelected = (
  teeths: TeethExtraction | undefined
): boolean => {
  if (teeths) return Object.entries(teeths).some(([, value]) => value.length);
  return false;
};

export const formatErrorList = (
  error: Record<string, any>,
  maplist: {
    field: string;
    label: string;
    innerFields: {
      field: string;
      label: string;
      animateLabel?: string;
    }[];
    animateLabel?: string;
  }[]
): ErrorList[] => {
  const errorList: ErrorList[] = maplist.reduce(
    (acc: ErrorList[], { field, innerFields, label, animateLabel }) => {
      if (error[field]) {
        if (innerFields.length) {
          const errorFields: ErrorList[] = [];
          innerFields.forEach(
            ({
              field: innerFieled,
              label: innerLabel,
              animateLabel: animateInnerLabel
            }) => {
              return (
                _.get(error, `${field}.${innerFieled}`) &&
                errorFields.push({
                  id: `${field}.${animateInnerLabel ?? innerFieled}`,
                  field,
                  label: `${translate(label)} ${
                    translate(innerLabel) ? '>' : ''
                  } ${translate(innerLabel)}`
                })
              );
            }
          );
          return [...acc, ...errorFields];
        }
        return [
          ...acc,
          {
            id: animateLabel ? animateLabel : field,
            label: translate(label),
            field
          }
        ];
      }
      return acc;
    },
    []
  );
  return errorList;
};

export const getButtonLabel = (activeState: string): ReferralButton => {
  switch (activeState) {
    case REFERRALS_STEPS.PATIENT_FILE:
      return { type: 'submit', label: 'submit_button_rounded_(short).button' };
    case REFERRALS_STEPS.REVIEW_SUMMARY:
      return { type: 'button', label: 'Reworked_No_Show.Black_ghost_button' };
    case REFERRALS_STEPS.PERSONAL_INFO:
    case REFERRALS_STEPS.TREATMENT_SPEC:
    default:
      return { type: 'submit', label: 'button.cta.continue' };
  }
};
export const formatClinicalPreferences = (
  data: TreatmentPLanPreferenceFormType,
  isPrescription: boolean,
  isZenPlus: boolean,
  isZenClear: boolean,
  isZenPlusRefinement: boolean,
  isZenClearRefinement: boolean,
  isRefinementPrescription?: boolean
) => {
  /**
   * Formats the clinical Preference to be send in the api input
   */
  let treatmentPlanData;
  if (isRefinementPrescription) {
    /**
     * This is to handle the refinement data from the prescription
     */
    treatmentPlanData = data?.Refinement;
  } else if (isZenPlus) {
    treatmentPlanData = data?.ZenPlus;
  } else if (isZenClear) {
    treatmentPlanData = data?.ZenClear;
  } else if (isZenPlusRefinement) {
    /**
     * This is to handle the refinement data from the preference template page
     */
    treatmentPlanData = data?.ZenPlusRefinement;
  } else if (isZenClearRefinement) {
    /**
     * This is to handle the refinement data from the preference template page
     */
    treatmentPlanData = data?.ZenClearRefinement;
  }
  const iprSizeInput = treatmentPlanData?.iprSize?.map((item: Option) => {
    return item?.value as string;
  });
  const iprLocationInput = treatmentPlanData?.iprLocation?.map(
    (item: Option) => {
      return item?.value as string;
    }
  );
  const others = treatmentPlanData?.others;
  if (isPrescription)
    return isZenPlus
      ? {
          iprSize: iprSizeInput,
          iprLocation: iprLocationInput,
          biteRamps: data?.ZenPlus?.biteRamps?.value,
          premolarCrossbite: data?.ZenPlus?.premolarCrossbite?.value,
          extrusionElastics: data?.ZenPlus?.extrusionElastics?.value,
          others: others || ''
        }
      : {
          iprSize: iprSizeInput,
          iprLocation: iprLocationInput,
          others: others || ''
        };
  else {
    if (isZenPlus) {
      return {
        ZenPlus: {
          iprSize: iprSizeInput?.sort(),
          iprLocation: iprLocationInput,
          biteRamps: data?.ZenPlus?.biteRamps?.value,
          premolarCrossbite: data?.ZenPlus?.premolarCrossbite?.value,
          extrusionElastics: data?.ZenPlus?.extrusionElastics?.value,
          others: others || ''
        }
      };
    } else if (isZenClear) {
      return {
        ZenClear: {
          iprSize: iprSizeInput?.sort(),
          iprLocation: iprLocationInput,
          others: others || ''
        }
      };
    } else if (isZenPlusRefinement) {
      return {
        ZenPlusRefinement: {
          iprSize: iprSizeInput?.sort(),
          iprLocation: iprLocationInput,
          others: others || ''
        }
      };
    } else if (isZenClearRefinement) {
      return {
        ZenClearRefinement: {
          iprSize: iprSizeInput?.sort(),
          iprLocation: iprLocationInput,
          others: others || ''
        }
      };
    }
  }
};

export const formatTreatmentSpecificationsForRefinementExternal = (
  refinementFormType: string,
  isResetClicked: boolean,
  isAutoSave: boolean,
  ClinicalPreferences: clinicalPreferencesRefinementType,
  treatmentSpecification: TreatmentSpecRefinementType,
  alignerAndIPRInfo?: AlignerIPRInfo
) => {
  const treatmentSpecifications: TreatmentSpecifications = {
    treatmentPlanTypes: [refinementFormType],
    tpv: {
      ...getRefinementFormData(
        refinementFormType === planTypeCode.ZEN_PLUS
          ? treatmentSpecification?.ZenPlus
          : treatmentSpecification?.ZenClear,
        refinementFormType === planTypeCode.ZEN_PLUS,
        alignerAndIPRInfo
      ),
      clinicalPreferences: isResetClicked
        ? {}
        : formatClinicalPreferences(
            ClinicalPreferences,
            true,
            false,
            false,
            false,
            false,
            true
          )
    },
    version: !isAutoSave ? 'V1' : undefined
  };
  return { treatmentSpecifications };
};
const getAlignerInput = (
  isSet: boolean,
  alignerData?: {
    radioValue: any;
    inputValue: any;
  },
  alignerAndIPRInfo?: AlignerIPRInfo
) => {
  if (isSet)
    return alignerData?.radioValue === ALIGNER_INFO_TYPE.SET &&
      alignerData?.inputValue
      ? alignerData?.inputValue?.split('/')?.[0]
      : getSetAndZenStageDefaultValues(
          alignerAndIPRInfo?.alignerInfo?.currentAligner,
          alignerAndIPRInfo?.alignerInfo?.totalSetAligner
        ).set;
  return ZENSTAGE_OPTIONS?.find(
    (option) =>
      option?.value ===
      (alignerData?.radioValue === ALIGNER_INFO_TYPE.ZEN_STAGE
        ? alignerData?.inputValue
        : `${
            getSetAndZenStageDefaultValues(
              alignerAndIPRInfo?.alignerInfo?.currentAligner,
              alignerAndIPRInfo?.alignerInfo?.totalSetAligner
            ).zenstage
          }`)
  );
};
export const getRefinementSpecialNotes = (
  isSubmitted: boolean,
  TreatmentSpecifications?: TreatmentSpecifications,
  isZenPlus?: boolean
) => {
  if (isSubmitted || TreatmentSpecifications?.tpv?.specialNotes) {
    return TreatmentSpecifications?.tpv?.specialNotes || '';
  } else {
    if (isZenPlus) return TreatmentSpecifications?.zenplusInstructions || '';
    else return TreatmentSpecifications?.treatmentPlanInstructions || '';
  }
};
export const getRefinementDefaultTreatmentSpec = (
  isSubmitted: boolean,
  isZenPlus: boolean,
  TreatmentSpecifications?: TreatmentSpecifications,
  alignerAndIPRInfo?: AlignerIPRInfo,
  hideLiveTranslation?: boolean
) => {
  const data = TreatmentSpecifications?.tpv;
  const refinementGoals: Option[] = [];
  data?.refinementGoals?.forEach((element: any) => {
    const option = REFINEMENT_GOALS_OPTIONS?.find(
      (item) => item?.value === element?.name
    );
    if (option) refinementGoals?.push(option);
  });
  const toothChart2: Record<string, string> = {};
  data?.refinementGoals
    ?.find(
      (option: any) => option?.name === REFINEMENT_GOALS.CLOSE_RESIDUAL_SPACES
    )
    ?.value?.forEach((item: any) => {
      toothChart2[item?.position] = item?.value.slice(0, -2);
    });

  const alignerUpperData = getAlignerData(data?.alignerInfo || [], 'upper');
  const alignerLowerData = getAlignerData(data?.alignerInfo || [], 'lower');

  const toothChart3 =
    data?.iprInfo?.name === REFINEMENT_IPR.NOT_AS_PRESCRIBED
      ? data?.iprInfo?.value?.map((item: any) => item?.position) || []
      : [];

  const attachmentsData = data?.refinementGoals
    ?.find(
      (option) => option?.name === REFINEMENT_GOALS.RESOLVE_POSTERIOR_OPEN_BITE
    )
    ?.value?.find(
      (item) =>
        item?.name === RESOLVE_POSTERIOR_OPEN_BITE_OPTIONS.ATTACHMENTS_ONLY ||
        item?.name === RESOLVE_POSTERIOR_OPEN_BITE_OPTIONS.SEE_RESULTING_PLAN
    );
  const buttonsForElasticsData = data?.refinementGoals
    ?.find(
      (option) => option?.name === REFINEMENT_GOALS.RESOLVE_POSTERIOR_OPEN_BITE
    )
    ?.value?.find(
      (item) =>
        item?.name ===
        RESOLVE_POSTERIOR_OPEN_BITE_OPTIONS.ADD_BUTTON_FOR_ELASTICS
    );

  const newdata = {
    reasonForSubmission:
      ZENCLEAR_TPV_REFINEMENT_FORM_REASON_WITH_ILL_FITTING?.find(
        (reason) =>
          ZENCLEAR_TPV_REFINEMENT_FORM_REASON_BACKEND[reason?.value] ===
          data?.submissionReason
      ) || ZENCLEAR_TPV_REFINEMENT_FORM_REASON_WITH_ILL_FITTING[0],
    archesToTreat:
      Number(data?.archesToTreat?.length) > 1
        ? ZENCLEAR_TPV_REFINEMENT_FORM_ARCHES_TO_TREAT[2]
        : ZENCLEAR_TPV_REFINEMENT_FORM_ARCHES_TO_TREAT?.find(
            (option) => option?.value === data?.archesToTreat?.[0]
          ) || ZENCLEAR_TPV_REFINEMENT_FORM_ARCHES_TO_TREAT[2],
    attachments:
      ZENCLEAR_TPV_REFINEMENT_FORM_ATTACHMENTS?.find(
        (option) => option?.value === data?.attachments
      ) || ZENCLEAR_TPV_REFINEMENT_FORM_ATTACHMENTS[0],
    iprPerformed:
      alignerAndIPRInfo?.iprInfo?.length === 0
        ? IPR_REFINEMENT_FORM_OPTIONS[0]
        : IPR_REFINEMENT_FORM_OPTIONS?.find(
            (option) => option?.value === data?.iprInfo?.name
          ) || IPR_REFINEMENT_FORM_OPTIONS[0],
    refinementGoals,
    specialNotes: getRefinementSpecialNotes(
      isSubmitted,
      TreatmentSpecifications,
      isZenPlus
    ),
    translatedSpecialNotes: hideLiveTranslation
      ? ''
      : data?.translatedSpecialNotes,
    radioUpper: alignerUpperData?.radioValue || ALIGNER_INFO_TYPE.SET,
    radioLower: alignerLowerData?.radioValue || ALIGNER_INFO_TYPE.SET,
    alignerInputUpperSet: getAlignerInput(
      true,
      alignerUpperData,
      alignerAndIPRInfo
    ),

    alignerInputLowerSet: getAlignerInput(
      true,
      alignerLowerData,
      alignerAndIPRInfo
    ),

    alignerInputUpperZenstage: getAlignerInput(
      false,
      alignerUpperData,
      alignerAndIPRInfo
    ),
    alignerInputLowerZenstage: getAlignerInput(
      false,
      alignerLowerData,
      alignerAndIPRInfo
    ),
    toothChart2,
    toothChart3,
    ...(!!isZenPlus && {
      buttonsForElastics:
        buttonsForElasticsData?.name ===
        RESOLVE_POSTERIOR_OPEN_BITE_OPTIONS.ADD_BUTTON_FOR_ELASTICS
          ? formatTeethsForController(buttonsForElasticsData?.value)
          : undefined
    }),
    attachmentsOnly:
      attachmentsData?.name ===
      RESOLVE_POSTERIOR_OPEN_BITE_OPTIONS.ATTACHMENTS_ONLY
        ? formatTeethsForController(attachmentsData?.value)
        : undefined,
    attachmentsSelect:
      ATTACHMENTS_OPTIONS?.find(
        (option) => option?.value === attachmentsData?.name
      ) || ATTACHMENTS_OPTIONS[0],
    designId: data?.designId,
    iprInfoText: data?.iprInfoText || '',
    formType: TPVFormTypes.ZENCLEAR,
    attachmentsOnlyCheck: !!data?.attachmentsOnlyCheck,
    ...(!!isZenPlus && {
      addButtonsForElasticsCheck: !!data?.addButtonsForElasticsCheck
    })
  };
  if (isZenPlus)
    return {
      ZenPlus: newdata,
      ZenClear: undefined,
      treatmentType: 'ZenPlus'
    };
  else
    return {
      ZenPlus: undefined,
      ZenClear: newdata,
      treatmentType: 'ZenClear'
    };
};
export const getCurrentDeliveryStage = (
  alignerProductionInfo?: AlignerProductionType
) => {
  return (
    alignerProductionInfo?.deliveryObject?.statusLabel ||
    alignerProductionInfo?.productionRecord?.statusLabel
  );
};
