import { FC } from 'react';

import classNames from 'classnames';
import { Controller, useFormContext } from 'react-hook-form';

import { RHFTextArea } from 'components/RHFTextArea/RHFTextArea';
import { translate } from 'utils';

import IprSizeAndLocationDropdowns from './IprSizeAndLocationDropdowns';

type Props = {
  iprSizeName: string;
  iprLocationName: string;
  isPrescription?: boolean;
  isVieworEdit?: boolean;
  isDisabled?: boolean;
  isReferral?: boolean;
  others: string;
};
const ZenClearPreferenceTemplate: FC<Props> = ({
  iprSizeName,
  iprLocationName,
  isPrescription = false,
  isVieworEdit = false,
  isDisabled = false,
  isReferral = false,
  others = ''
}) => {
  const { control } = useFormContext();
  return (
    <div
      className={classNames({
        'rounded-lg': isPrescription && !isVieworEdit,
        'bg-GRAY5': isPrescription && !isVieworEdit && !isReferral
      })}
    >
      <IprSizeAndLocationDropdowns
        isDisabled={isDisabled}
        isVieworEdit={isVieworEdit}
        isPrescription={isPrescription}
        iprLocationName={iprLocationName}
        iprSizeName={iprSizeName}
        isReferral={isReferral}
      />
      <div
        className={classNames('space-y-6', {
          ' px-5 pt-2 py-6 rounded-lg': isPrescription && !isVieworEdit,
          'mt-7': !isPrescription || isVieworEdit
        })}
      >
        <Controller
          control={control}
          name={others}
          render={() => (
            <>
              <RHFTextArea
                title={isPrescription ? '3. Others' : '4. Others'}
                labelclassName='text-sm mb-2'
                name={others}
                autogrow
                inputProps={{
                  disabled: isDisabled,
                  placeholder: translate('settings.please_type_here')
                }}
                fontClass='text-sm'
                maxgrowHeight={260}
              />
            </>
          )}
        />
      </div>
    </div>
  );
};

export default ZenClearPreferenceTemplate;
