import { FC } from 'react';

import classNames from 'classnames';
import _ from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';

import { ErrorIcon } from 'assets/icons';
import { FormLabel, RhfCheckboxGroup } from 'components';
import {
  IPR_LOCATION,
  IPR_OPTIONS
} from 'constants/treatmentPlanningPreferenceTemplate';
import { translate } from 'utils';
import { RemoveCheckedOptions } from 'utils/patientpageUtils';

type IprSizeAndLocationDropdownsProps = {
  iprSizeName: string;
  iprLocationName: string;
  isPrescription?: boolean;
  isVieworEdit?: boolean;
  isDisabled?: boolean;
  isReferral?: boolean;
};
const IprSizeAndLocationDropdowns: FC<IprSizeAndLocationDropdownsProps> = ({
  iprSizeName,
  iprLocationName,
  isPrescription = false,
  isVieworEdit = false,
  isDisabled = false,
  isReferral = false
}) => {
  const {
    control,
    formState: { errors }
  } = useFormContext();
  return (
    <div
      className={classNames({
        'px-5 py-4 rounded-lg': isPrescription && !isVieworEdit,
        'bg-GRAY5': isPrescription && !isVieworEdit && !isReferral
      })}
    >
      <Controller
        control={control}
        name={iprSizeName}
        render={({ field }) => (
          <div className='mb-6'>
            <FormLabel
              label={
                isPrescription
                  ? `1. ${translate('ipr.size')}`
                  : `2. ${translate('ipr.size')}`
              }
              error={!!_.get(errors, iprSizeName)}
              name={iprSizeName}
              className='text-sm'
            />
            <RhfCheckboxGroup
              disabled={isDisabled}
              onChange={(data, name, e) => {
                field.onChange(RemoveCheckedOptions(data, 'no Preferences', e));
              }}
              options={IPR_OPTIONS}
              name={iprSizeName}
              className='mt-1'
              checkBoxItemFont='text-sm md:flex md:flex-wrap grid grid-cols-3'
              labelClassName='md:mr-14'
              gridTemplateColumnsWidth={'25% 25% 50%'}
            />
            {!!_.get(errors, iprSizeName) &&
              (isVieworEdit || !isPrescription) && (
                <p className='text-ERROR text-xs mt-2 flex'>
                  <ErrorIcon />
                  <span className='ml-2'>
                    {translate(
                      _.get(errors, iprSizeName)?.message?.toString() || ''
                    )}
                  </span>
                </p>
              )}
          </div>
        )}
      />
      <Controller
        control={control}
        name={iprLocationName}
        render={({ field }) => (
          <div>
            <FormLabel
              label={
                isPrescription
                  ? `2. ${translate('ipr.location')}`
                  : `3. ${translate('ipr.location')}`
              }
              error={!!_.get(errors, iprLocationName)}
              name={iprLocationName}
              className='text-sm'
            />
            <RhfCheckboxGroup
              disabled={isDisabled}
              options={IPR_LOCATION}
              name={iprLocationName}
              className='mt-1'
              checkBoxItemFont='text-sm md:flex md:flex-wrap grid grid-cols-3'
              labelClassName='md:mr-[52px]'
              gridTemplateColumnsWidth={'25% 25% 50%'}
              onChange={(data, name, e) => {
                field.onChange(RemoveCheckedOptions(data, 'no Preferences', e));
              }}
            />
            {!!_.get(errors, iprLocationName) &&
              (isVieworEdit || !isPrescription) && (
                <p className='text-ERROR text-xs mt-2 flex '>
                  <ErrorIcon />
                  <span className='ml-2'>
                    {translate(
                      _.get(errors, iprLocationName)?.message?.toString() || ''
                    )}
                  </span>
                </p>
              )}
          </div>
        )}
      />
    </div>
  );
};

export default IprSizeAndLocationDropdowns;
