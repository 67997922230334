import { FC, useEffect } from 'react';

import cx from 'classnames';
import { useFormContext } from 'react-hook-form';

import { Button, RhfRadioGroup } from 'components';
import { Option } from 'types/select';
import { TemplateFormTypes } from 'types/TPVForm.types';
import { translate } from 'utils';

import ZenClearPreferenceTemplate from './ZenClearPreferenceTemplate';
import ZenPLusPreferenceTemplate from './ZenPlusPreferenceTemplate';
type Props = {
  typeOptions: Option[];
  template: string;
  onClickSaveTemplate: () => void;
  setShowTemplatePreviewPage?: (status: boolean) => void;
  isVieworEdit: boolean;
  isPrescription?: boolean;
  isView?: boolean;
};
const PreferenceTemplate: FC<Props> = ({
  isPrescription = false,
  template,
  isVieworEdit = false,
  onClickSaveTemplate,
  isView = false,
  typeOptions,
  setShowTemplatePreviewPage
}) => {
  const { watch, setValue } = useFormContext();
  const watchTreatmentPlanType = watch('treatmentPlanType');
  useEffect(() => {
    setValue('treatmentPlanType', template);
  }, [template]);

  return (
    <section className={cx(' p-6 rounded-lg', { 'bg-white': isVieworEdit })}>
      {!isVieworEdit && (
        <div className='mb-6'>
          <RhfRadioGroup
            title={`1. ${translate('prescriptions.treatment_plan_type')}`}
            titleClassName='font-normal relative overflow-hidden text-DEFAULT_TEXT md:text-sm'
            options={typeOptions}
            name='treatmentPlanType'
            radioClassName='border-RADIO_BORDER '
            className='mt-1 flex flex-col md:flex-row'
            radioItemFont='md:text-17px text-sm flex'
            optionClassName='mr-18'
          />
        </div>
      )}
      {watchTreatmentPlanType === TemplateFormTypes.ZenPlusRefinement && (
        <div>
          <ZenClearPreferenceTemplate
            isDisabled={isView}
            isVieworEdit={isVieworEdit}
            isPrescription={isPrescription}
            iprLocationName={'ZenPlusRefinement.iprLocation'}
            iprSizeName={'ZenPlusRefinement.iprSize'}
            others='ZenPlusRefinement.others'
          />
        </div>
      )}
      {watchTreatmentPlanType === TemplateFormTypes.ZenClearRefinement && (
        <div>
          <ZenClearPreferenceTemplate
            isDisabled={isView}
            isVieworEdit={isVieworEdit}
            isPrescription={isPrescription}
            iprLocationName={'ZenClearRefinement.iprLocation'}
            iprSizeName={'ZenClearRefinement.iprSize'}
            others='ZenClearRefinement.others'
          />
        </div>
      )}
      {watchTreatmentPlanType === TemplateFormTypes.ZenClear && (
        <div>
          <ZenClearPreferenceTemplate
            isDisabled={isView}
            isVieworEdit={isVieworEdit}
            isPrescription={isPrescription}
            iprLocationName={'ZenClear.iprLocation'}
            iprSizeName={'ZenClear.iprSize'}
            others='ZenClear.others'
          />
        </div>
      )}
      {watchTreatmentPlanType === TemplateFormTypes.ZenPlus && (
        <div>
          <ZenPLusPreferenceTemplate
            isDisabled={isView}
            isVieworEdit={isVieworEdit}
            isPrescription={isPrescription}
            fieldNames={{
              iprSizeName: 'ZenPlus.iprSize',
              iprLocationName: 'ZenPlus.iprLocation',
              biteRampsName: 'ZenPlus.biteRamps',
              premolarCrossbiteName: 'ZenPlus.premolarCrossbite',
              extrusionElastics: 'ZenPlus.extrusionElastics',
              others: 'ZenPlus.others'
            }}
          />
        </div>
      )}
      {!isVieworEdit && (
        <div className='flex items-center justify-end '>
          <Button
            className='text-PRIMARY border-PRIMARY border-1  px-6 py-3 mt-6 mr-2 disabled:border-none'
            type='button'
            onClick={() => setShowTemplatePreviewPage?.(true)}
          >
            {translate('cancel.cancel')}
          </Button>
          <Button
            className='bg-PRIMARY rounded-full mt-6 text-WHITE px-6 py-3 xxs:w-full md:w-auto'
            type='button'
            onClick={() => {
              onClickSaveTemplate();
            }}
          >
            {translate('Save.template')}
          </Button>
        </div>
      )}
    </section>
  );
};

export default PreferenceTemplate;
